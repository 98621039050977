import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { GetProductCategory, Alerts } from "../../../../helpers";
import CKEditorField from "../../../../components/cmp/CKEditorField";
import MediaLibraryPopup from "../../../../components/cmp/MediaLibraryPopup";
import { FiEdit } from "react-icons/fi";
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Categories",
    link: "/app/product/categories",
  },
  {
    id: 3,
    label: "Create New",
    link: "/app/product/categories/create",
  },
];

const CreateCategory = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [showMedia, setShowMedia] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const getCategories = async () => {
    let resp = await GetProductCategory.getAllCategories();
    if (resp && resp.status) {
      setCategories(resp.data);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { parent_category, name, description, short_description, cat_thumb, is_home } = values;
    const data = {
      ...(parent_category && parent_category.trim() !== ""
        ? { parent_id: parent_category }
        : {}),
      title: name,
      short_description,
      description,
      is_home:is_home,
      media_id: cat_thumb,
    };

  

    let resp = await GetProductCategory.createCategory(data);
    if (resp && resp.status) {

      Alerts.alertMessage(resp.message, "top-right", "success");
      resetForm();
      setSubmitting(false);
      navigate("/app/product/categories/");
    } else {
      Alerts.alertMessage(resp.message, "top-right", "error");
    }
  };
  const handleSelectImage = (image) => {
    setImageFile(image);
    setShowMedia(false);
  };
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <BiCategory /> Create Product Categories
            </h2>
            <Link to="/app/product/categories/" className="btn btn-success">
              <BiCategory /> Categories
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  name: "",
                  short_description:"",
                  description: "",
                  parent_category: "",
                  cat_thumb: null,
                  is_home:false
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Category name is required";
                  }
                  if (!values.description) {
                    errors.description = "Category description is required";
                  }
                  if (!values.cat_thumb) {
                    errors.cat_thumb = "Category thumbnail is required";
                  }

                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <span className="help-error">{errors.name}</span>
                          )}
                        </div>

                        <div className="form-group">
                          <label>Short Description</label>
                          <textarea
                            className="form-control"
                            name="short_description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description}
                          ></textarea>
                          {errors.short_description &&
                            touched.short_description && (
                              <span className="help-error">
                                {errors.short_description}
                              </span>
                            )}
                        </div>

                        <div className="form-group">
                          <label>Description</label>
                          <Field
                            name="description"
                            render={({ field, form }) => (
                              <CKEditorField field={field} form={form} />
                            )}
                          />
                          {errors.description && touched.description && (
                            <span className="help-error">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Category Thumbnail</label>
                          <div className="dropzone-box">
                            {imageFile !== "" && (
                              <img
                                src={imageFile.path}
                                alt="product_thumbnail"
                              />
                            )}
                            {imageFile !== "" ? (
                              <button
                                type="button"
                                className="floatBtn"
                                onClick={() => setShowMedia(true)}
                              >
                                <FiEdit size={15} />
                              </button>
                            ) : (
                              <>
                                <p>Drop files to upload</p>
                                <p>or</p>
                                <button
                                  type="button"
                                  onClick={() => setShowMedia(true)}
                                  className="choose-btn"
                                >
                                  Choose file
                                </button>
                              </>
                            )}
                          </div>
                          {errors.cat_thumb && touched.cat_thumb && (
                            <span className="help-error text-center">
                              {errors.cat_thumb}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Parent Category</label>
                          <select
                            className="form-control"
                            name="parent_category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.parent_category}
                          >
                            <option value={""}>---Select Category---</option>
                            {categories.length !== 0 &&
                              categories.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group">
                          <div className="form-check-custom col-md-12 mt-0">
                            <input
                              type="checkbox"
                              id="is_home"
                              name="is_home"
                              checked={values.is_home}
                              onChange={() => {
                                setFieldValue("is_home", !values.is_home)
                              }}
                            />
                            <label htmlFor="is_home">
                              Show on Home
                            </label>
                          </div>
                          
                        </div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: 50,
                          }}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                    {showMedia && (
                      <MediaLibraryPopup
                        show={showMedia}
                        setShowMedia={setShowMedia}
                        handleSelectImage={handleSelectImage}
                        setFieldValue={setFieldValue}
                        fieldName="cat_thumb"
                      />
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCategory;
