import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import { GetProductCategory, Alerts, GetProducts } from "../../../helpers";
import CKEditorField from "../../../components/cmp/CKEditorField";
import Select from "react-select";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FiEdit } from "react-icons/fi";
import MediaLibraryPopup from "../../../components/cmp/MediaLibraryPopup";
import AddVariations from "../../../components/variations/AddVariations";
import AddProductMedia from "../../../components/variations/product/AddProductMedia";
import AddProductContent from "../../../components/variations/product/AddProductContent";
import AddProductSpecification from "../../../components/variations/product/AddProductSpecification";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Products",
        link: "/app/products",
    },
    {
        id: 3,
        label: "Edit Products",
        link: "#",
    },
];

const ProductDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [categoryOptions, setCategoriesOption] = useState([]);
    const [productData, setProductData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [showMedia, setShowMedia] = useState(false);
    const [product_thumb, setProductThumb] = useState('');
    const [activeKey, setActiveKey] = useState("product_details");


    const getEditProduct = async (product_id) => {
        let resp = await GetProducts.getSingleProducts(product_id);
        console.log(resp);
        if (resp && resp.status) {
            setProductData(resp.data);
            setProductThumb(resp.data.product_thumbnail);
            setLoading(false);
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error');
            navigate("/app/products");
        }
    }

    const getCategories = async () => {
        let resp = await GetProductCategory.getAllCategories();
        if (resp && resp.status) {
            const optionsValue = resp.data.map(item => ({
                value: item.id,
                label: item.title
            }));
            setCategoriesOption(optionsValue);
        }
    };

    useEffect(() => {
        getCategories();
        getEditProduct(id);
        const savedTab = localStorage.getItem("activeTab");
        if (savedTab) {
          setActiveKey(savedTab);
        }
    }, [id]);

    const handleSelect = (key) => {
        setActiveKey(key);
        localStorage.setItem("activeTab", key);
      };

    const handleSubmit = async (values, { setSubmitting }) => {
        const data = {
            title: values.title,
            short_description: values.short_description,
            description: values.description,
            specification: values.specification,
            regular_price: values.regular_price,
            sale_price: values.sale_price,
            tax_status: values.tax_status,
            best_seller: values.best_seller ? 1 : 0,
            sku: values.sku,
            stock: values.stock,
            product_image: values.product_image,
            status: values.status,
            start_variation:values.start_variation,
            isVariation: values.isVariation ? 1 : 0,
            variation_image: values.isVariation ? values.variation_image ? 1 : 0 : 0,
            categories: values.categories
        }
        let resp = await GetProducts.updateProduct(data, id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, "top-right", "success");
            setTimeout(()=>{
              window.location.reload();
            },2000);
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
        // resetForm();
        setSubmitting(false);
    };

    const handleSelectImage = (image) => {
        setProductThumb(image);
        setShowMedia(false);
    }



    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Product Details
                        </h2>
                        <Link to="/app/products" className="btn btn-success">
                            <BiCategory /> Products
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            {
                                isLoading ? <div className='loader'>
                                    <img src='/images/loader.gif' alt='loader' />
                                </div> :
                                    <Tabs
                                        defaultActiveKey="product_details"
                                        activeKey={activeKey}
                                        onSelect={handleSelect}
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="product_details" title="Product Details">
                                            <Formik
                                                enableReinitialize
                                                initialValues={{
                                                    title: productData.title,
                                                    slug:productData.slug,
                                                    short_description: productData.short_description,
                                                    description: productData.description,
                                                    specification: productData.specification,
                                                    regular_price: productData.regular_price,
                                                    sale_price: productData.sale_price,
                                                    tax_status: productData.tax_status,
                                                    best_seller: productData.best_seller,
                                                    sku: productData.sku,
                                                    stock: productData.stock,
                                                    product_image: productData.product_thumbnail?.id,
                                                    status: productData.status,
                                                    isVariation: productData.isVariation,
                                                    variation_image: productData.variation_image,
                                                    categories: productData.categories,
                                                    start_variation:productData.start_variation || ''
                                                }}
                                                validate={(values) => {
                                                    const errors = {};
                                                    if (!values.title) {
                                                        errors.title = "Title is required";
                                                    }
                                                    if (!values.slug) {
                                                        errors.slug = "Slug is required";
                                                    }
                                                   
                                                    if (!values.isVariation) {
                                                        if (!values.short_description) {
                                                            errors.short_description = "Short description is required";
                                                        }
                                                        if (!values.description) {
                                                            errors.description = "Description is required";
                                                        }
                                                        // if (!values.specification) {
                                                        //     errors.specification = "Specification is required";
                                                        // }
                                                        
                                                        if (!values.regular_price) {
                                                            errors.regular_price = "Regular price is required";
                                                        } else if (isNaN(values.regular_price)) {
                                                            errors.regular_price = "Regular price is number or decimal value";
                                                        }
                                                        if (!values.sale_price) {
                                                            errors.sale_price = "Sale price is required";
                                                        } else if (isNaN(values.sale_price)) {
                                                            errors.sale_price = "Sale price is number or decimal value";
                                                        }

                                                        if (!values.sku) {
                                                            errors.sku = "SKU is required";
                                                        }
                                                        if (!values.stock) {
                                                            errors.stock = "Stock is required";
                                                        }
                                                    }
                                                    //   if (!values.product_image) {
                                                    //       errors.product_image = "Product image is required";
                                                    //   }

                                                    return errors;
                                                }}
                                                onSubmit={handleSubmit}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    setFieldValue, 
                                                }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row">
                                                        <div className={`col-md-8`}>
                                                                <div className="form-group">
                                                                    <label>Product Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="title"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.title}
                                                                    />
                                                                    {errors.title && touched.title && (
                                                                        <span className="help-error">{errors.title}</span>
                                                                    )}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Product Slug</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="slug"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.slug}
                                                                    />
                                                                    {errors.slug && touched.slug && (
                                                                        <span className="help-error">{errors.slug}</span>
                                                                    )}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Short description</label>
                                                                    <Field
                                                                        name="short_description">
                                                                        {({ field, form }) => (
                                                                            <CKEditorField field={field} form={form} />
                                                                        )}
                                                                    </Field>


                                                                    {errors.short_description &&
                                                                        touched.short_description && (
                                                                            <span className="help-error">
                                                                                {errors.short_description}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                               {
                                                                !values.isVariation && <>
                                                                 
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <Field name="description">
                                                                        {({ field, form }) => (
                                                                            <CKEditorField field={field} form={form} />
                                                                        )}
                                                                    </Field>

                                                                    {errors.description && touched.description && (
                                                                        <span className="help-error">
                                                                            {errors.description}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {/* <div className="form-group">
                                                                    <label>Product Specification</label>
                                                                    <Field name="specification">
                                                                        {({ field, form }) => (
                                                                            <CKEditorField field={field} form={form} />
                                                                        )}
                                                                    </Field>
                                                                    {errors.specification && touched.specification && (
                                                                        <span className="help-error">
                                                                            {errors.specification}
                                                                        </span>
                                                                    )}
                                                                </div> */}
                                                                </>
                                                               }
                                                               



                                                            </div>

                                                            <div className={`col-md-4`}>
                                                                <div className="form-group">
                                                                    <label>Product Thumbnail</label>
                                                                    <div className="dropzone-box">
                                                                        {
                                                                            product_thumb !== '' && <img src={product_thumb?.path} alt="product_thumbnail" />
                                                                        }
                                                                        {
                                                                            product_thumb !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <button type="button" onClick={() => setShowMedia(true)}>Choose your file</button>
                                                                        }
                                                                    </div>


                                                                    {errors.product_image && touched.product_image && (
                                                                        <span className="help-error">
                                                                            {errors.product_image}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="form-group row">
                                                                    {
                                                                        values.isVariation &&  <div className="col-md-6">
                                                                        <div className="form-check-custom">
                                                                            <input type="checkbox" value={values.isVariation} checked={values.isVariation} id="isVariation" name="isVariation" onChange={handleChange}
                                                                                onBlur={handleBlur} />
                                                                            <label htmlFor="isVariation">
                                                                                Variation Product
                                                                            </label>
                                                                        </div>
                                                                        </div>
                                                                    }
                                                                   
                                                                    <div className="col-md-6">
                                                                    {
                                                                    values.isVariation && ( 
                                                                    <div className="form-group ">
                                                                        <div className="form-check-custom">
                                                                            {values.variation_image}
                                                                            <input type="checkbox" value={values.variation_image} id="variation_image" name="variation_image" onChange={handleChange}
                                                                                onBlur={handleBlur} checked={values.variation_image} />
                                                                            <label htmlFor="variation_image">
                                                                                Variation Wise Image
                                                                            </label>
                                                                        </div>
                                                                    </div>)
                                                                }
                                                                    </div>
                                                                   
                                                                </div>
                                                                {
                                                                    values.isVariation && ( 
                                                                    <div className="form-group ">
                                                                         <label>Start Variation</label>
                                                                         <select className="form-control" name="start_variation" onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.start_variation}>
                                                                              <option value={''}>--Select One--</option>
                                                                              {
                                                                                productData.variation_keys.length > 0 && productData.variation_keys.map(((el, i)=>{
                                                                                   return  <option key={i} value={el.key}>{el.key}</option>
                                                                                }))
                                                                              }
                                                                             
                                                                         </select>
                                                                    </div>)
                                                                }
                                                                
                                                                {

                                                                    !values.isVariation && <>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <label>Regular Price</label>
                                                                                <div className="priceBox">
                                                                                <div className="priceIcon">£</div>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="regular_price"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.regular_price}
                                                                                    placeholder="0.00"
                                                                                />
                                                                                </div>
                                                                                {errors.regular_price && touched.regular_price && (
                                                                                    <span className="help-error">
                                                                                        {errors.regular_price}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Sale Price</label>
                                                                                <div className="priceBox">
                                                                                <div className="priceIcon">£</div>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="sale_price"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.sale_price}
                                                                                    placeholder="0.00"
                                                                                />
                                                                                </div>
                                                                                {errors.sale_price && touched.sale_price && (
                                                                                    <span className="help-error">
                                                                                        {errors.sale_price}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-6">
                                                                                <label>SKU</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="sku"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.sku}
                                                                                />
                                                                                {errors.sku && touched.sku && (
                                                                                    <span className="help-error">{errors.sku}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Total Stock</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="stock"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.stock}
                                                                                />
                                                                                {errors.stock && touched.stock && (
                                                                                    <span className="help-error">{errors.stock}</span>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }

                                                                <div className="form-group row">
                                                                    <div className="col-md-6">
                                                                        <label>Tax Status</label>
                                                                        <select className="form-control" name="tax_status" onChange={handleChange}
                                                                            onBlur={handleBlur} value={values.tax_status ? 1 : 0}>
                                                                            <option value={0}>No</option>
                                                                            <option value={1}>Yes</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-6">

                                                                        <div className="form-check-custom">
                                                                            <input type="checkbox" value={values.best_seller} id="best_sale" name="best_seller" onChange={handleChange}
                                                                                onBlur={handleBlur} checked={values.best_seller} />
                                                                            <label htmlFor="best_sale">
                                                                                Best Saller
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Categories</label>
                                                                    <Field name="categories">
                                                                        {({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                value={categoryOptions.filter((option) =>
                                                                                    values.categories.includes(option.value)
                                                                                )}
                                                                                onChange={(selectedOptions) =>
                                                                                    setFieldValue(
                                                                                        "categories",
                                                                                        selectedOptions.map(
                                                                                            (option) => option.value
                                                                                        )
                                                                                    )
                                                                                }
                                                                                options={categoryOptions}
                                                                                placeholder="Select categories"
                                                                                isMulti
                                                                                className="select2"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Status</label>
                                                                    <select className="form-control" name="status" onChange={handleChange}
                                                                        onBlur={handleBlur} defaultValue={values.status ? 1 : 0}>
                                                                        <option value={1}>Published</option>
                                                                        <option value={0}>Draft</option>
                                                                    </select>
                                                                </div>
                                                                <div className="text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary "
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? "Updating..." : "Update Now"}
                                                                </button>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        {
                                                            showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image" />
                                                        }
                                                    </form>
                                                )}
                                            </Formik>
                                        </Tab>
                                        {
                                            productData.isVariation &&   <Tab eventKey="variations" title="Variations" disabled={!productData.isVariation}>
                                            <AddVariations productData={productData} product_id={productData.id} product_variations={productData.product_variations} />
                                        </Tab>
                                        }
                                      
                                        <Tab eventKey="specification" title="Specification">
                                           <AddProductSpecification productData={productData} product_id={productData.id}/>
                                        </Tab>
                                         { !productData.variation_image  && <Tab eventKey="other_details" title="Images & Videos" disabled={productData.variation_image}>
                                            <AddProductMedia product_id={productData.id} />
                                        </Tab>
                                        }
                                    </Tabs>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
