import api from '../../ApiConfig';
import { Apis } from '../../config';

var token = localStorage.getItem('userToken');
const getAllInventoryProducts = async () => {
  try {

    let result = await api.get(Apis.GetInventoryProducts, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    return null;
  }
};

  const updateInventory = async (product_id, sku,  data) => {
    try {
      let result = await api.put(`${Apis.UpdateInventory+product_id}/${sku}`, data,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

const exportedObject = {
    getAllInventoryProducts,
    updateInventory
};
export default exportedObject;