import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { MdOutlineInventory } from "react-icons/md";
import { Alerts, GetInventory } from '../../../helpers';
import MUIDataTable from "mui-datatables";
import ThemeModal from '../../../components/cmp/ThemeModal';
import { FaEdit } from 'react-icons/fa';
import { Formik } from 'formik';


const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Inventory",
        link: "/app/inventory",
    }
];



const options = {
    filter: false,
    download: false,
    print: false,
    selectableRows: false
};

const columns = [
    {
        name: 'SL'
    },
    {
        name: 'SKU',
        sortable: true,
    },
    {
        name: 'Product',
        sortable:true
    },
    {
        name: 'Regular Price',
    },
    {
        name: 'Sale Price',
    },
    {
        name: 'Stock',
    },
    {
        name: 'Image',
    },
    {
        name: 'Actions',
    }
];

const InventoryProducts = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isEdit, setEdit] = useState(false);
    const [product, setProduct] = useState(null);

    const handleOpenEdit = (data) => {
        console.log(data);
        setProduct(data);
        setEdit(true);
    }

    const handleCloseEdit = () => {
        setProduct(null);
        setEdit(false);
    }


    const getProducts = async () => {
        const resp = await GetInventory.getAllInventoryProducts();
        if (resp && resp.status) {
            setLoading(false);
            setProducts(resp.product_list);
            console.log(resp.product_list);
        }
    }

    useEffect(() => {
        getProducts();
    }, [])


   const handleUpdateInventory = async (values, { setSubmitting }) =>{
       const product_id = product.id;
       const sku = product.sku;

      const resp = await GetInventory.updateInventory(product_id, sku, values);
      if(resp && resp.status){
        Alerts.alertMessage(resp.message, 'top-right', 'success');
        handleCloseEdit();
        getProducts();
        setSubmitting(false);
      }
   }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className='container-fluid'>
                    <div className='page-title'>
                        <h2>
                            <MdOutlineInventory />
                            Inventory</h2>
                    </div>
                    {
                        isLoading ? <div className='loader'>
                            <img src='/images/loader.gif' alt='loader' />
                        </div> : <>
                            <MUIDataTable
                                title=""
                                data={products.map((item, i) => {

                                    return [
                                        ++i,
                                        item.sku,
                                        item.title,
                                        <div style={{width:150}}>{item.regular_price}</div>,
                                        <div style={{width:150}}>{item.sale_price}</div>,
                                        item.stock,
                                        <img src={item.product_image} alt="thumbnail" width="50px" />,
                                        <button className='btn btn-info btn-sm' onClick={() => handleOpenEdit(item)}><FaEdit size={14} /></button>
                                    ]
                                })}
                                columns={columns}
                                options={options}
                            />

                            {
                                isEdit && product !== null && <ThemeModal show={isEdit} handleHide={handleCloseEdit} title={'Update Inventory'} isFullscreen={false}>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{ regular_price: product.regular_price, sale_price: product.sale_price, stock: product.stock }}
                                        validate={values => {
                                            const errors = {};
                                      
                                            if (!values.regular_price || isNaN(values.regular_price) || Number(values.regular_price) <= 0) {
                                                errors.regular_price = 'Enter a valid regular price.';
                                            }

                                            if (!values.sale_price || isNaN(values.sale_price) || Number(values.sale_price) < 0 || Number(values.sale_price) > Number(values.regular_price)) {
                                                errors.sale_price = 'Sale price must be valid and ≤ Regular price.';
                                            }

                                            if (!values.stock || isNaN(values.stock) || Number(values.stock) < 0) {
                                                errors.stock = 'Stock must be a valid non-negative number.';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleUpdateInventory}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <h6>{product.title}</h6>
                                                <br />
                                                <div className='form-group'>
                                                    <label>Regular Price</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name="regular_price"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.regular_price}
                                                    />
                                                      {errors.regular_price && touched.regular_price && <span className="help-error">{errors.regular_price}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label>Sale Price</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name="sale_price"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.sale_price}
                                                    />
                                                      {errors.sale_price && touched.sale_price && <span className="help-error">{errors.sale_price}</span>}
                                                </div>
                                                <div className='form-group'>
                                                    <label>Stock</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name="stock"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.stock}
                                                    />
                                                      {errors.stock && touched.stock && <span className="help-error">{errors.stock}</span>}
                                                </div>
                                                <div className='text-center'>
                                                    <button className='btn btn-primary btn-sm' disabled={isSubmitting}>{isSubmitting ? 'Please wait..':'Save'}</button>
                                                </div>

                                            </form>
                                        )}
                                    </Formik>
                                </ThemeModal>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default InventoryProducts;