import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { IoIosSettings } from "react-icons/io";
import { GetSettings, Alerts } from '../../../helpers';
import { Formik } from 'formik';
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Currency",
    link: "/app/product/categories",
  }
];

const Settings = () => {
  const [settings, setSettings] = useState('');

  const getSettings = async () => {
    const resp = await GetSettings.getSettings();
    if (resp && resp.status) {
      setSettings(resp.vat_setting);
    }
  }

  const updateVatSetting = async (values, {setSubmitting}) =>{
       const resp = await GetSettings.updateVatSettings(values);
       if(resp && resp.status){
         setSubmitting(false);
         Alerts.alertMessage(resp.message, "top-right", "success");
       }
  }

  useEffect(() => {
    getSettings();
  }, []);
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <IoIosSettings /><span>Settings</span>
            </h2>

          </div>
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className='col-md-4'>
                  <div className="card">
                    <div className='card-header'>
                      Vat Settings
                    </div>
                    <div className="card-body">
                      {
                        settings !== '' &&  <Formik
                        enableReinitialize
                        initialValues={{ vat_value: settings.vat_value, vat_type: settings.vat_type }}
                        validate={values => {
                          const errors = {};
                          if (!values.vat_value) {
                            errors.vat_value = 'Vat value is required';
                          }
                          if (!values.vat_type) {
                            errors.vat_type = 'Vat type is required';
                          }
                          return errors;
                        }}
                        onSubmit={updateVatSetting}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                              <label>Vat Value</label>
                              <input type='text' 
                               className='form-control'
                               name="vat_value"
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.vat_value}
                               />
                                {errors.vat_value && touched.vat_value && (
                                          <span className="help-error">{errors.vat_value}</span>
                                      )}
                            </div>
                            <div className='form-group'>
                              <label>Vat Type</label>
                              <select 
                                className='form-control'
                                name="vat_type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.vat_type}
                                >
                                <option value={'fixed'}>Fixed</option>
                                <option value={'percentage'}>Percentage</option>
                              </select>
                              {errors.vat_type && touched.vat_type && (
                                          <span className="help-error">{errors.vat_type}</span>
                                      )}
                            </div>
                            <button className='btn btn-primary' type="submit" disabled={isSubmitting}>
                              {isSubmitting ? 'Please wait..' : 'Save'}</button>
                          </form>
                        )}
                      </Formik>
                      }
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings