import React, { useState, useEffect } from 'react'
import { Specifications, Alerts } from '../../../helpers';
import { Formik } from 'formik';
import MUIDataTable from 'mui-datatables';
import ConfModal from '../../cmp/ConfModal';
import MyModal from '../../cmp/MyModal';
import { FaEdit } from 'react-icons/fa';
const AddProductSpecification = ({ productData, product_id }) => {
    const [specifications, setSpecifications] = useState([]);
    const [product_specifications, setProductSpecifications] = useState([]);
    const [selectedSpecification, setSelectedSpecification] = useState(null);
    const [isUpdate, setUpdate] = useState(false);
    const [selectedVariation, setSelectedVariation] = useState(productData.product_variations[0]);

        const handleOpenUpdate = (spec) => {
            setSelectedSpecification(spec);
            setUpdate(true);
        }
    
        const handleCloseUpdate = () => {
            setSelectedSpecification(null);
            setUpdate(false);
        }
    
                                       
    const getSpecification = async () => {
        const resp = await Specifications.getAllSpecification();

        if (resp && resp.status) {
            setSpecifications(resp.specifications);
        }
    }

    const getProductSpecifications = async (pro_id) => {
        const resp = await Specifications.getProductSpecification(pro_id);
        console.log(resp);
        if (resp && resp.status) {
            setProductSpecifications(resp.specifications);
        }
    }

    useEffect(() => {
        getSpecification();
        getProductSpecifications(product_id);
    }, [])


    const options = {
        filter: false,
        download:false,
        print:false,
        selectableRows: false
      };
    
      const columns = [
        {
          name: 'SL'
        },
        {
            name: 'Name',
            sortable: true,
          },
        {
          name: 'value',
          sortable: true,
        },
        {
          name: 'Actions',
          sortable: false,
        },
      ];
      




    const handleSubmitSpecification = async (values, { setSubmitting, resetForm }) => {
        const resp = await Specifications.createProductSpecification(values);
        if (resp && resp.status) {
            setSubmitting(false);
            resetForm();
            getProductSpecifications(product_id);
            Alerts.alertMessage(resp.message, "top-right", "success");
        } else {
            setSubmitting(false);
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
    }


    const handleDeleteSpecification = async (id) => {
        const resp = await Specifications.deleteProductSpecification(id);
        if (resp && resp.status) {
            getProductSpecifications(product_id);
            Alerts.alertMessage(resp.message, "top-right", "success");
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
    }

    const handleUpdateSpecification = async (values) => {
       const resp = await Specifications.updateProductSpecification(selectedSpecification.id, values);
       if(resp && resp.status){
        getProductSpecifications(product_id);
        handleCloseUpdate();
        Alerts.alertMessage(resp.message, "top-right", "success");
       } else {
           Alerts.alertMessage(resp.message, "top-right", "error");
       }
    }

    return (
        <>
           {
            productData && productData.isVariation ? productData.product_variations.length === 0 ?<p>Add Variations first !</p>  :  <div className='row'>
                <div className='col-md-4'>
                        
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            product_id: product_id,
                            product_variation_id:selectedVariation?.id,
                            specification_id: "",
                            value: ""
                        }}
                        validate={(values) => {
                            const errors = {};
                            
                            if (!values.product_variation_id) {
                                errors.product_variation_id = "Variation is required";
                            }else{
                                const data = productData.product_variations.find(el=>el.id === parseInt(values.product_variation_id));
                                setSelectedVariation(data)
                            }
                            if (!values.specification_id) {
                                errors.specification_id = "Specification  is required";
                            }
                            if (!values.value) {
                                errors.value = "Specification value is required";
                            }
                            return errors;
                        }}
                        onSubmit={handleSubmitSpecification}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className='form-group'>
                                    <label>Variations</label>
                                    <select className='form-control' name='product_variation_id' onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.product_variation_id}>
                                        <option value={''}>Select One</option>
                                        {
                                             productData.product_variations.map((item, i) => {
                                                return <option value={item.id}>{item.variations}</option>
                                            })
                                        }
                                    </select>
                                    {errors.product_variation_id && touched.product_variation_id && (
                                        <span className="help-error">{errors.product_variation_id}</span>
                                    )}
                                </div>
                                <div className='form-group'>
                                    <label>Specification</label>
                                    <select className='form-control' name='specification_id' onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.specification_id}>
                                        <option value={''}>Select One</option>
                                        {
                                            specifications.map((item, i) => {
                                                return <option value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.specification_id && touched.specification_id && (
                                        <span className="help-error">{errors.specification_id}</span>
                                    )}
                                </div>
                                <div className="form-group ">
                                    <label>Value</label>
                                    <input type='text' className='form-control' name="value" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.value} />
                                    {errors.value && touched.value && (
                                        <span className="help-error">{errors.value}</span>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Please wait..." : "Save"}
                                </button>

                            </form>
                        )}
                    </Formik>
                </div>
                <div className='col-md-8'>
                    <MUIDataTable
                        title="Specifications"
                        data={product_specifications
                            .filter(item => item.product_variation_id  ===  selectedVariation?.id) 
                            .map((item, i) => {

                            return [
                                ++i,
                                item.specification.name,
                                item.value,
                                <div className={`actions`} style={{ width: "150px" }}>
                                       <button className="btn btn-sm btn-info" onClick={()=>handleOpenUpdate(item)}><FaEdit size={14}/></button> &nbsp;
                                      <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={handleDeleteSpecification} /> &nbsp;
            
                                </div>
                            ]
                        })}
                        columns={columns}
                        options={options}
                    />
                    {
                         isUpdate && selectedSpecification && <MyModal show={isUpdate} setAddNew={handleCloseUpdate} title={'Update Specification'}>
                          <Formik
                        enableReinitialize={true}
                        initialValues={{
                            product_id:selectedSpecification.product_id,
                            product_variation_id:selectedVariation?.id,
                            specification_id:selectedSpecification.specification_id,
                            value: selectedSpecification.value
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.specification_id) {
                                errors.specification_id = "Specification  is required";
                            }
                            if (!values.value) {
                                errors.value = "Specification value is required";
                            }
                            return errors;
                        }}
                        onSubmit={handleUpdateSpecification}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className='form-group'>
                                    <label>Specification</label>
                                    <select className='form-control' name='specification_id' onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.specification_id}>
                                        <option value={''}>Select One</option>
                                        {
                                            specifications.map((item, i) => {
                                                return <option value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    {errors.specification_id && touched.specification_id && (
                                        <span className="help-error">{errors.specification_id}</span>
                                    )}
                                </div>
                                <div className="form-group ">
                                    <label>Value</label>
                                    <input type='text' className='form-control' name="value" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.value} />
                                    {errors.value && touched.value && (
                                        <span className="help-error">{errors.value}</span>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Please wait..." : "Save"}
                                </button>

                            </form>
                        )}
                    </Formik>
                </MyModal>
            }
                    
                </div>
            </div> :  <div className='row'>
            <div className='col-md-4'>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        product_id: product_id,
                        specification_id: "",
                        value: ""
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.specification_id) {
                            errors.specification_id = "Specification  is required";
                        }
                        if (!values.value) {
                            errors.value = "Specification value is required";
                        }
                        return errors;
                    }}
                    onSubmit={handleSubmitSpecification}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>

                            <div className='form-group'>
                                <label>Specification</label>
                                <select className='form-control' name='specification_id' onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.specification_id}>
                                    <option value={''}>Select One</option>
                                    {
                                        specifications.map((item, i) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })
                                    }
                                </select>
                                {errors.specification_id && touched.specification_id && (
                                    <span className="help-error">{errors.specification_id}</span>
                                )}
                            </div>
                            <div className="form-group ">
                                <label>Value</label>
                                <input type='text' className='form-control' name="value" onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.value} />
                                {errors.value && touched.value && (
                                    <span className="help-error">{errors.value}</span>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Please wait..." : "Save"}
                            </button>

                        </form>
                    )}
                </Formik>
            </div>
            <div className='col-md-8'>
                <MUIDataTable
                    title="Variations"
                    data={product_specifications.map((item, i) => {

                        return [
                            ++i,
                            item.specification.name,
                            item.value,
                            <div className={`actions`} style={{ width: "150px" }}>
                                   <button className="btn btn-sm btn-info" onClick={()=>handleOpenUpdate(item)}><FaEdit size={14}/></button> &nbsp;
                                   <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={handleDeleteSpecification} /> &nbsp;
                                {/*  <Link className="btn btn-success btn-sm" to={`/app/product/category/variations/option/${item.id}`}><FaEye size={18}/></Link> */}
                            </div>
                        ]
                    })}
                    columns={columns}
                    options={options}
                />
                {
                     isUpdate && selectedSpecification && <MyModal show={isUpdate} setAddNew={handleCloseUpdate} title={'Update Specification'}>
                      <Formik
                    enableReinitialize={true}
                    initialValues={{
                        product_id:selectedSpecification.product_id,
                        specification_id:selectedSpecification.specification_id,
                        value: selectedSpecification.value,

                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.specification_id) {
                            errors.specification_id = "Specification  is required";
                        }
                        if (!values.value) {
                            errors.value = "Specification value is required";
                        }
                        return errors;
                    }}
                    onSubmit={handleUpdateSpecification}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>

                            <div className='form-group'>
                                <label>Specification</label>
                                <select className='form-control' name='specification_id' onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.specification_id}>
                                    <option value={''}>Select One</option>
                                    {
                                        specifications.map((item, i) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })
                                    }
                                </select>
                                {errors.specification_id && touched.specification_id && (
                                    <span className="help-error">{errors.specification_id}</span>
                                )}
                            </div>
                            <div className="form-group ">
                                <label>Value</label>
                                <input type='text' className='form-control' name="value" onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.value} />
                                {errors.value && touched.value && (
                                    <span className="help-error">{errors.value}</span>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Please wait..." : "Save"}
                            </button>

                        </form>
                    )}
                      </Formik>
            </MyModal>
        }
                
            </div>
        </div>
           }
           

        </>
    )
}

export default AddProductSpecification