import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');
const getAllSpecification = async () => {
  try {
    let result = await api.get(Apis.GetSpecifications, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createSpecification = async (data) => {
    try {
      let result = await api.post(Apis.CreateSpecification, data,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const deleteSpecification = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteSpecification+id,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateSpecification = async (id, data) => {
    try {
      let result = await api.put(Apis.EditSpecification+id, data,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  //Product
  const createProductSpecification = async (data) => {
    try {
      let result = await api.post(Apis.CreateProductSpecific, data,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const getProductSpecification = async (pro_id) => {
    try {
      let result = await api.get(Apis.GetProductSpecification+pro_id,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  
  const deleteProductSpecification = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteProductSpecification+id,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateProductSpecification = async (id, data) => {
    try {
      let result = await api.put(Apis.UpdateProductSpecification+id, data,{
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  
  
  
  
const exportedObject = {
    getAllSpecification,
    createSpecification,
    deleteSpecification,
    updateSpecification,
    createProductSpecification,
    getProductSpecification,
    deleteProductSpecification,
    updateProductSpecification
};
export default exportedObject;