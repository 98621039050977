import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { FiEdit } from 'react-icons/fi';
import MediaLibraryPopup from '../../cmp/MediaLibraryPopup';
import { GetProducts, Alerts } from '../../../helpers';
import ProductGallery from './ProductGallery';
const AddProductMedia = ({ product_id }) => {
    const [product_thumb, setProductThumb] = useState('');
    const [showMedia, setShowMedia] = useState(false);
    const [mediaData, setMediaData] = useState([]);


    const handleSelectImage = (image) => {
        setProductThumb(image);
        setShowMedia(false);
    }

    const getProductMedia = async (pro_id) => {
        let resp = await GetProducts.getProductGallery(pro_id);
       console.log(resp);
        if (resp && resp.status) {
            setMediaData(resp.data);
        }
    };

    useEffect(() => {
        getProductMedia(product_id);
    }, [product_id]);


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const data = {
            media_id: values.product_image
        }
        let resp = await GetProducts.createProductGallery(data, product_id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, "top-right", "success");
            getProductMedia(product_id);
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
        setProductThumb('');
        resetForm();
        setSubmitting(false);
    };

    const deleteRecord = async (id) => {
        let resp = await GetProducts.deleteProductGallery(id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            getProductMedia(product_id);
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error');
        }
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <Formik
                        initialValues={{
                            product_image: '',
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.product_image) {
                                errors.product_image = "Product image is required";
                            }

                            return errors;
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="row">


                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Select your file</label>
                                            <div className="dropzone-box">
                                                {
                                                    product_thumb !== '' && (
                                                        <>
                                                            {
                                                                product_thumb.file_type === 'image' ? <img src={product_thumb.path} alt="product_thumbnail" /> : product_thumb.file_type === 'video' ? <video width="320" height="180" controls>
                                                                <source src={product_thumb.path} type="video/mp4"/>
                                                              </video> : product_thumb.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: 100 }} />
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    product_thumb !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                                        {/* <p>Drop files to upload</p>
                                                <p>or</p> */}
                                                        <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                                                    </>
                                                }
                                            </div>


                                            {errors.product_image && touched.product_image && (
                                                <span className="help-error text-center mt-3">
                                                    {errors.product_image}
                                                </span>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Uploading..." : "Upload Now"}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                {
                                    showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image" />
                                }
                            </form>


                        )}
                    </Formik>
                </div> 
                <div className='col-md-12' style={{ marginTop: 20 }}>
                    {
                        mediaData && mediaData.length > 0 && <ProductGallery mediaFiles={mediaData} handleDeleteImage={deleteRecord}/>
                    }
                  
                    {/* <MUIDataTable
                        title="Product Gallery"
                        data={mediaData.map((item, i) => {
                            return [
                                ++i,
                                item.media_file.file_type === 'image' ? <img src={item.media_file.path} alt="product_thumbnail" style={{ width: 70 }} /> : item.media_file.file_type === 'video' ? <img src={'/images/video.svg'} alt="product_thumbnail" style={{ width: 70 }} /> : item.media_file.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: 70 }} />,
                                item.media_file.file_type,
                                format(item.createdAt, 'dd/MM/yyyy'),
                                <div className={`actions`}>
                                    <button className="btn btn-success btn-sm" onClick={() => handleView(item)}><FiEye size={14} /></button>&nbsp;
                                    <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={deleteRecord} />
                                </div>
                            ]
                        })}
                        columns={columns}
                        options={options}
                    /> */}
                    
                </div>
            </div>



        </div>

    )
}

export default AddProductMedia