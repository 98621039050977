import React, { useState, useEffect } from "react";
import { Alerts, GetProductCategory, GetProducts } from "../../helpers";
import { MdWarning, MdContentPaste } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfModal from "../cmp/ConfModal";
import { FaImages } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import AddProductGallery from "./product/AddProductGallery";
import MediaLibraryPopup from "../cmp/MediaLibraryPopup";
import AddProductContent from "./product/AddProductContent";
import VariationGallery from "./product/VariationGallery";

const AddVariations = ({ product_id, productData }) => {
    const [variations, setVariations] = useState([]);
    const [visibleInputs, setVisibleInputs] = useState({});
    const [productVariations, setProductVariations] = useState([]);
    const [isAddImage, setAddImage] = useState(false);
    const [productVariation, setProductVariation] = useState(null);
    const [showMedia, setShowMedia] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [isAddContent, setContent] = useState(false);
    const [isUnit, setIsUnit] = useState(false);

    const addProductVariationContent = (value) => {
        setProductVariation(value);
        setContent(true);
    }
    const closeProductVariationContent = () => {
        setContent(false);
        setProductVariation(null);
        getProductVar(product_id);
    }

    const handleShowAddImage = (value) => {
        setProductVariation(value);
        setAddImage(true)
    }
    const handleCloseAddImage = () => {
        setAddImage(false);
        setProductVariation(null);
        setImageFile('');
        getProductVar(product_id);

    }
    const handleSelectImage = (value) => {
        setImageFile(value);
        setShowMedia(false);
    }
    const handleImageSubmit = async () => {
        const data = {
            product_variation_id: productVariation.id,
            media_id: imageFile.id
        }
        const resp = await GetProducts.createProductVariationGallery(data);
        if (resp && resp.status) {
            const updatedObject = {
                ...productVariation,
                variation_galleries: [
                    ...productVariation.variation_galleries,
                    resp.variationMedia
                ]
            };

            setProductVariation(updatedObject);
            setImageFile('');
            Alerts.alertMessage(resp.message, 'top-right', 'success');
        } else {
            Alerts.alertMessage('Something went wrong.', 'top-right', 'error')
        }
    }

    const handleDeleteVariationImage = async (id) => {
        const resp = await GetProducts.deleteProductVariationGallery(id);
        if (resp && resp.status) {
            const filteredObject = {
                ...productVariation,
                variation_galleries: productVariation.variation_galleries.filter(
                    (gallery) => gallery.id !== id
                ),
            };

            setProductVariation(filteredObject);
            Alerts.alertMessage(resp.message, 'top-right', 'success');
        } else {
            Alerts.alertMessage('Something went wrong.', 'top-right', 'error')
        }
    }
    //End images

    const getProductVar = async (productId) => {
        const resp = await GetProducts.getProductVariation(productId);
        console.log(resp);
        if (resp && resp.status) {
            setProductVariations(resp.variations);
        }
    }

    const setInitialVisibility = (variationData) => {
        const initialVisibility = variationData.reduce((acc, variation) => {
            acc[variation.name] = false;
            return acc;
        }, {});
        setVisibleInputs(initialVisibility);
    }
    const getVariations = async (id) => {
        const resp = await GetProductCategory.getVariations();
       
        if (resp && resp.status) {
            setVariations(resp.variations);
            setInitialVisibility(resp.variations);

            const initialValues = resp.variations.reduce((acc, variation) => {

                acc[variation.slug] = "";
                return acc;

            }, { sku: "", stock: "", regular_price: "", sale_price: "", unit_value: "" });
            formik.setValues(initialValues);
        }
    };

    const toggleInputVisibility = (name) => {
        setVisibleInputs((prev) => ({
            ...prev,
            [name]: !prev[name],
        }));
    };
    useEffect(() => {
        getVariations(product_id);
        getProductVar(product_id);
    }, [product_id]);


    const deleteVariation = async (id) => {
        const resp = await GetProducts.deleteProductVariation(id);
        if (resp && resp.status) {
            getProductVar(product_id);
            Alerts.alertMessage(resp.message, 'top-right', 'success');
        } else {
            Alerts.alertMessage('Something went wrong.', 'top-right', 'error')
        }
    }

    const generateValidationSchema = () => {
        let schema = {
            sku: Yup.string()
                .typeError("SKU must be a string")
                .required("SKU is required"),
            stock: Yup.number()
                .typeError("Quantity must be a number")
                .min(1, "Quantity must be at least 1")
                .required("Quantity is required"),
            regular_price: Yup.number()
                .typeError("Price must be a number")
                .min(1, "Price must be at least 1")
                .required("Price is required"),
            sale_price: Yup.number()
                .typeError("Price must be a number")
                .required("Price is required"),
        };
        if (isUnit) {
            schema.unit_value = Yup.number()
                .typeError("Unit value must be a number")
                .required("Unit value is required");
            schema.unit_name = Yup.string()
                .typeError("Unit name must be a string")
                .required("Unit name is required");
        
        }

        variations.forEach((variation) => {
            if (variation) {
                if (visibleInputs[variation.name]) {
                    schema[variation.slug] = Yup.string().required(`${variation.name} is required`);
                }
            }

        });

        return Yup.object(schema);
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            sku: "",
            stock: "",
            regular_price: "",
            sale_price: "",
            unit_value: "",
            unit_name:""
        },
        validationSchema: generateValidationSchema(),
        onSubmit: async (values, { resetForm }) => {
            const filteredValues = Object.keys(values).reduce((acc, key) => {
                if (values[key] && visibleInputs[key] !== false) {
                    acc[key] = values[key];
                }
                return acc;
            }, {});

            const { sku, stock, regular_price, sale_price, unit_value, unit_name, ...updatedObj } = filteredValues;
            const data = {
                productId: product_id,
                variations: updatedObj,
                sku: sku,
                stock: stock,
                regular_price: regular_price,
                sale_price: sale_price,
                unit_value: unit_value ? unit_value : "",
                unit_name:unit_name ? unit_name : ""
            }
            console.log(data);

            const resp = await GetProducts.createProductVariations(data);
            if (resp && resp.status) {
                getProductVar(product_id);
                setTimeout(() => {
                    window.location.reload();
                }, 2000)

                Alerts.alertMessage(resp.message, 'top-right', 'success');
            } else {
                Alerts.alertMessage(resp.message, 'top-right', 'error')
            }
        },
        enableReinitialize: true,
    });




    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
                    <div className="col-md-3">
                        <label htmlFor="sku">SKU</label>
                        <input
                            type="text"
                            id="sku"
                            name="sku"
                            className="form-control"
                            placeholder="Enter SKU"
                            value={formik.values.sku}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.sku && formik.errors.sku ? (
                            <div style={{ color: "red", fontSize: 12 }}>
                                {formik.errors.sku}
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="stock">Stock</label>
                        <input
                            type="number"
                            id="stock"
                            name="stock"
                            className="form-control"
                            placeholder="Enter quantity"
                            value={formik.values.stock}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.stock && formik.errors.stock ? (
                            <div style={{ color: "red", fontSize: 12 }}>
                                {formik.errors.stock}
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="regular_price">Price</label>
                        <div className="priceBox">
                        <div className="priceIcon">£</div>
                        <input
                            type="number"
                            id="regular_price"
                            name="regular_price"
                            className="form-control"
                            placeholder="Enter price"
                            value={formik.values.regular_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        </div>
                        {formik.touched.regular_price && formik.errors.regular_price ? (
                            <div style={{ color: "red", fontSize: 12 }}>
                                {formik.errors.regular_price}
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="sale_price">Sale Price</label>
                        <div className="priceBox">
                            <div className="priceIcon">£</div>
                            <input
                            type="number"
                            id="sale_price"
                            name="sale_price"
                            className="form-control"
                            placeholder="Enter sale price"
                            value={formik.values.sale_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        </div>
                       
                        {formik.touched.sale_price && formik.errors.sale_price ? (
                            <div style={{ color: "red", fontSize: 12 }}>
                                {formik.errors.sale_price}
                            </div>
                        ) : null}
                    </div>




                </div>
                <div className="row" style={{ marginTop: 15 }}>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor={`isVariation`} className="isUnit">
                                <input
                                    type="checkbox"
                                    name="variation"
                                    id={`isVariation`}
                                    onClick={()=>setIsUnit(!isUnit)}
                                />
                               <span>IsUnit Details</span> 
                            </label>
                        </div>
                        {
                            isUnit && (
                                <>
                                 <div className="col-md-3">
                                <label htmlFor="unit_value">Unit Value</label>
                                <input
                                    type="text"
                                    id="unit_value"
                                    name="unit_value"
                                    className="form-control"
                                    placeholder="Enter unit value"
                                    value={formik.values.unit_value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.unit_value && formik.errors.unit_value ? (
                                    <div style={{ color: "red", fontSize: 12 }}>
                                        {formik.errors.unit_value}
                                    </div>
                                ) : null}
                            </div>
                                <div className="col-md-3">
                                <label htmlFor="unit_name">Unit Name</label>
                                
                                <input
                                    type="text"
                                    id="unit_name"
                                    name="unit_name"
                                    className="form-control"
                                    placeholder="Enter unit name"
                                    value={formik.values.unit_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.unit_name && formik.errors.unit_name ? (
                                    <div style={{ color: "red", fontSize: 12 }}>
                                        {formik.errors.unit_name}
                                    </div>
                                ) : null}
                            </div>
                                </>
                               
                            
                            )
                        }
                       
                    </div>

                </div>
                <div className="variationList">
                    <h5>Variations</h5>
                    <ul>
                        {variations.length > 0 ? (
                            variations.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <label htmlFor={`variation_${i}`}>
                                            <input
                                                type="checkbox"
                                                name="variation"
                                                id={`variation_${i}`}

                                                onChange={() =>
                                                    toggleInputVisibility(item.name)
                                                }
                                            />
                                            {item.name}
                                        </label>
                                        {visibleInputs[item.name] && (
                                            <div style={{ marginTop: 10, marginRight: 5 }}>
                                                {item.input_type === "text" && (
                                                    <input
                                                        type="text"
                                                        placeholder={`Enter ${item.name}`}
                                                        className="form-control mr-3"
                                                        id={item.name}
                                                        name={item.slug}
                                                        value={formik.values[item.slug]}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                )}
                                                {item.input_type === "number" && (
                                                    <input
                                                        type="number"
                                                        placeholder={`Enter ${item.name}`}
                                                        className="form-control"
                                                        id={item.name}
                                                        name={item.slug}
                                                        value={formik.values[item.slug]}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                )}
                                                {item.input_type === "textarea" && (
                                                    <textarea
                                                        placeholder={`Enter ${item.name}`}
                                                        className="form-control"
                                                        rows="3"
                                                        id={item.name}
                                                        name={item.slug}
                                                        value={formik.values[item.slug]}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    ></textarea>
                                                )}
                                                {formik.touched[item.slug] &&
                                                    formik.errors[item.slug] && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {formik.errors[item.slug]}
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    </li>
                                );
                            })
                        ) : (
                            <p
                                style={{
                                    color: "#881212",
                                    fontSize: 14,
                                    marginTop: 10,
                                }}
                            >
                                <MdWarning size={18} /> Variation not found!
                            </p>
                        )}
                    </ul>
                </div>




                <button type="submit" className="btn btn-primary" style={{ marginTop: 20 }}>
                    Submit
                </button>
            </form>
            <div className="card" style={{ marginTop: 20 }}>
                <div className="card-header">
                    <h4>Variations</h4>
                </div>
                <div className="card-body">
                    <div className="variation-list">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Variation</th>
                                    <th>SKU</th>
                                    <th>Regular Price</th>
                                    <th>Sale Price</th>
                                    <th>Unit Details</th>
                                    <th>Stock</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productVariations ? productVariations.length > 0 ? productVariations.map((el, index) => {
                                        const variationData = JSON.parse(el.variations);
                                        return <tr key={index}>
                                            <td style={{ maxWidth: 200 }}>{el.variations}</td>
                                            <td>{el.sku}</td>
                                            <td>{el.regular_price}</td>
                                            <td>{el.sale_price}</td>
                                            <td>{el.unit_value !== null && `${el.unit_value}/${el.unit_name}`}</td>
                                            <td>{el.stock}</td>
                                            <td><ConfModal message={'Are you sure you want to delete this record'} delId={el.id} handleDelete={deleteVariation} /> &nbsp;
                                                {
                                                    productData.variation_image && <button className="btn btn-info btn-sm" onClick={() => handleShowAddImage(el)}><FaImages /></button>
                                                }
                                                &nbsp;<button className="btn btn-success btn-sm" onClick={() => addProductVariationContent(el)}><MdContentPaste /></button>
                                            </td>
                                        </tr>
                                    }) : 'Record not found !' : 'Record not found !'
                                }
                            </tbody>
                        </table>

                        {
                            productVariation !== null && <AddProductContent show={isAddContent} productVariation={productVariation} handleClose={closeProductVariationContent} />


                        }
                        {
                            productVariation !== null && <AddProductGallery show={isAddImage} productVariation={productVariation} handleClose={handleCloseAddImage}>
                                <p><strong>Variation Details</strong></p>
                                <p>{productVariation.variations}</p>
                                <br />
                                <div className="dropzone-box">

                                    {imageFile.file_type === 'image' ? <img src={imageFile.path} alt="product_thumbnail" style={{ width: '100%', maxWidth: 250 }} /> : imageFile.file_type === 'video' ? <video width="300" height="260" controls={false}><source src={imageFile.path} type="video/mp4" /></video> : imageFile.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: 70 }} />}
                                    {
                                        imageFile !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                            {/* <p>Drop files to upload</p>
                                            <p>or</p> */}
                                            <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                                        </>
                                    }
                                </div>
                                <br />
                                {
                                    imageFile !== '' && <div className="text-center" style={{marginBottom:10}}>
                                        <button className="btn btn-primary btn-sm" onClick={handleImageSubmit}>Add Media</button>
                                        
                                    </div>
                                }

                                {
                                    productVariation && productVariation.variation_galleries.length > 0 &&
                                    <VariationGallery productVariation={productVariation} handleDeleteVariationImage={handleDeleteVariationImage} />
                                }

                                {
                                    showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} fieldName="variation_image" />
                                }


                            </AddProductGallery>
                        }
                    </div>
                </div>
            </div>

        </>

    );
};

export default AddVariations;


