import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');

const getAllProducts = async () => {
    try {
      let result = await api.get(Apis.GetAllProducts, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

const createProduct = async (data) => {
    try {
      let result = await api.post(Apis.CreateProduct, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateProduct = async (data, id) => {
    try {
      let result = await api.put(Apis.UpdateProduct+id, data, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };


  const getSingleProducts = async (id) => {
    try {
      let result = await api.get(Apis.GetSingleProduct+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const createProductGallery = async (data,id) => {
    try {
      let result = await api.post(Apis.CreateProductGallery+id, data, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const getProductGallery = async (id) => {
    try {
      let result = await api.get(Apis.GetProductGallery+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const deleteProductGallery = async (id) => {
    try {
      let result = await api.delete(Apis.ProductGalleryDelete+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const createProductVariations = async (data) => {
    try {
      let result = await api.post(Apis.CreateProductVariations, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const getProductVariation = async (id) => {
    try {
      let result = await api.get(Apis.GetProductVariation+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const deleteProductVariation = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteProductVariation+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const createProductVariationGallery = async (data) => {
    try {
      let result = await api.post(Apis.CreateProductVariationGallery, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const deleteProductVariationGallery = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteProductVariationGallery+id, {
          headers: {
            'x-access-token': token
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };


  const createProductVariationContent = async (data) => {
    try {
      let result = await api.post(Apis.CreateProductVariationContent, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const updateProductVariationContent = async (data, id) => {
    try {
      let result = await api.put(Apis.UpdateProductVariationContent+id, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
 
  const updateGalleryOrder = async (data) => {
    try {
      let result = await api.post(Apis.UpdateVariationGalleryPosition, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateProductGalleryOrder = async (data) => {
    try {
      let result = await api.post(Apis.UpdateGalleryPosition, data, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const deleteProductWithDetails = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteProduct+id, {
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  
  const createProductFaq = async (data) => {
    try {
      let result = await api.post(Apis.CreateProductFaq, data,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  
  const getProductFaqs = async (product_id) => {
    try {
      let result = await api.get(Apis.GetProductFaqs+product_id,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  const getSingleProductFaq = async (faq_id) => {
    try {
      let result = await api.get(Apis.GetSingleProduct+faq_id,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const getDeleteProductFaq = async (faq_id) => {
    try {
      let result = await api.delete(Apis.DeleteProductFaq+faq_id,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const updateProductFaq = async (data, faq_id) => {
    try {
      let result = await api.put(Apis.UpdateProductFaq+faq_id, data,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };


  const getProductReviews = async () => {
    try {
      let result = await api.get(Apis.GetReviews,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };
  
  const updateReviews = async (id, data) => {
    try {
      let result = await api.put(Apis.UpdateReviews+id, data,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const deleteReviews = async (id) => {
    try {
      let result = await api.delete(Apis.DeleteReviews+id,{
          headers: {
            'x-access-token': token,
          }
        });
      if (result.data.error) {
        return null;
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  };

  const exportedObject = {
    createProduct,
    updateProduct,
    getAllProducts,
    getSingleProducts,
    createProductGallery,
    getProductGallery,
    deleteProductGallery,
    createProductVariations,
    getProductVariation,
    deleteProductVariation,
    createProductVariationGallery,
    deleteProductVariationGallery,
    createProductVariationContent,
    updateProductVariationContent,
    updateGalleryOrder,
    updateProductGalleryOrder,
    deleteProductWithDetails,
    createProductFaq,
    getProductFaqs,
    getSingleProductFaq,
    getDeleteProductFaq,
    updateProductFaq,
    getProductReviews,
    updateReviews,
    deleteReviews

};
export default exportedObject;