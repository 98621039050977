import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Alerts, GetProductCategory } from "../../../helpers";
import Select from 'react-select';
import MUIDataTable from "mui-datatables";
import ConfModal from "../../../components/cmp/ConfModal";
import UpdateVariation from "../../../components/variations/UpdateVariation";
import CreateVariationOption from "../../../components/variations/options/CreateVariationOption";
import { FaEdit, FaEye } from "react-icons/fa";
import MyModal from "../../../components/cmp/MyModal";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Variations1",
        link: "/app/product/category/variations1",
    }
];

const CreateVariation = () => {
    const [variations, setVariations] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [isUpdate, setUpdate] = useState(false);

    const handleOpenUpdate = (variation) =>{
       setSelectedVariation(variation);
       setUpdate(true);
    }

    const handleCloseUpdate = () =>{
        setSelectedVariation(null);
        setUpdate(false);
    }

    const handleUpdate = async (values, { setSubmitting, resetForm }) => {
        const data = {
            neyKey:values.name,
            keyToFind:selectedVariation.slug,
            inputType:values.input_type
        }
        const resp = await GetProductCategory.updateVariationValue(data, selectedVariation.id);
        if (resp && resp.status) {
            getVariations();
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            setSubmitting(false);
            resetForm();
            handleCloseUpdate()
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error')
        }
    }


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const resp = await GetProductCategory.createVariation(values);
        if (resp && resp.status) {
            getVariations();
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            setSubmitting(false);
            resetForm();
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error')
        }
    };

    const getVariations = async () => {
        const resp = await GetProductCategory.getVariations();
        console.log(resp);
        if (resp && resp.status) {
            setVariations(resp.variations);
        }
    }

    useEffect(() => {
        getVariations();
    }, []);



    const handleDelete = async (id) => {
        const resp = await GetProductCategory.deleteVariation(id);
        if (resp && resp.status) {
            getVariations();
            Alerts.alertMessage(resp.message, 'top-right', 'success');

        } else {
            Alerts.alertMessage('Something went wrong !', 'top-right', 'danger')
        }
    }



    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };


    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Variations',
            sortable: true,
        },
        {
            name: 'Input Type',
            sortable: true,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Variation
                        </h2>
                       
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            input_type:''
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.name) {
                                                errors.name = "Variation name is required";
                                            }
                                            if (!values.input_type) {
                                                errors.input_type = "Variation input type is required";
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                    {errors.name && touched.name && (
                                                        <span className="help-error">{errors.name}</span>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Input Type</label>
                                                    <select className="form-control" 
                                                        name="input_type"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.input_type}
                                                        >
                                                        <option value={''}>---Select---</option>
                                                        <option value={'text'}>Text</option>
                                                        <option value={'number'}>Number</option>
                                                        <option value={'textarea'}>Textarea</option>
                                                    </select>
                                                    {errors.input_type && touched.input_type && (
                                                        <span className="help-error">{errors.input_type}</span>
                                                    )}
                                                </div>


                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-md-8">
                                <MUIDataTable
                                        title="Variations"
                                        data={variations.map((item, i) => {

                                            return [
                                                ++i,
                                                item.name,
                                                item.input_type,
                                                <div className={`actions`} style={{ width: "150px" }}>
                                                     <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={handleDelete} /> &nbsp;
                                                     <button className="btn btn-sm btn-info" onClick={()=>handleOpenUpdate(item)}><FaEdit size={14}/></button> &nbsp;
                                                     <CreateVariationOption variation={item} getVariations={getVariations}/>
                                                 </div>
                                            ]
                                        })}
                                        columns={columns}
                                        options={options}
                                    />

                                    {
                                        isUpdate && selectedVariation && <MyModal show={isUpdate} setAddNew={handleCloseUpdate} title={'Update Variation'}>
                                                 <Formik
                                                 enableReinitialize={true}
                                        initialValues={{
                                            name: selectedVariation.name,
                                            input_type: selectedVariation.input_type,
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.name) {
                                                errors.name = "Variation name is required";
                                            }
                                            if (!values.input_type) {
                                                errors.input_type = "Variation input type is required";
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleUpdate}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                    {errors.name && touched.name && (
                                                        <span className="help-error">{errors.name}</span>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Input Type</label>
                                                    <select className="form-control" 
                                                        name="input_type"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.input_type}
                                                        >
                                                        <option value={''}>---Select---</option>
                                                        <option value={'text'}>Text</option>
                                                        <option value={'number'}>Number</option>
                                                        <option value={'textarea'}>Textarea</option>
                                                    </select>
                                                    {errors.input_type && touched.input_type && (
                                                        <span className="help-error">{errors.input_type}</span>
                                                    )}
                                                </div>


                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </button>
                                            </form>
                                        )}
                                    </Formik> 
                                        </MyModal>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateVariation;
