import React,{useState} from 'react'
import { Link, useLocation  } from 'react-router-dom'

import { FiChevronDown } from 'react-icons/fi';
import menuData from '../../assets/data/menu.json'
import Icon from './Icon'
// const Sidebar = ({toggleSidebar}) => {
//     const location = useLocation();
//     const pathname = location.pathname;
    
//     const basePath = pathname.split("/").slice(0, 3).join("/") + "/";
//     const [showDropdownId, setShowDropdownId] = useState(null);
//     const setDropdownId = (id)=>{
//         if(id === showDropdownId){
//             setShowDropdownId(null)
//         }else{
//             setShowDropdownId(id)
//         }
       
//     }
//     return (
//         <div className={`layoutSidebar ${toggleSidebar ? 'hide' : ''}`}>
//             <Link to={'/'}>
//             <div className='brand'>
//                 <img src='/images/logo.png' alt="" />
//             </div>
//             </Link>
           
//             <ul className='sidebarMenu'>
//                 {
//                     menuData && menuData.length !== 0 && menuData.map(item=>{
//                         if(item.submenu){
//                             return <li key={item.label} className={` ${showDropdownId === item.id || item.base_path === basePath ? ' dropdownMenu opened' : 'dropdownMenu'}`}>
//                             <div className='dropdownLabel'><Link to="/"><Icon icon={item.icon}/> <span>{item.label}</span></Link> 
//                             <button  onClick={()=>setDropdownId(item.id)}><FiChevronDown/></button></div>
//                            <div className={`dropdownList ${showDropdownId === item.id || item.base_path === basePath ?  'show' : ''} `}>
//                               <ul>
//                                 {
//                                     item.submenu.length !== 0 && item.submenu.map(subItem=>{
//                                         return <li key={subItem.label}><Link to={subItem.link}>{subItem.label}</Link></li>
//                                     })
//                                 }
//                               </ul>
//                            </div>
//                         </li>
//                         }
//                         return <li key={item.label}><Link to={item.link}><Icon icon={item.icon}/><span>{item.label}</span></Link></li>
                      
//                     })
//                 }
//             </ul>
//         </div>
//     )
// }

// export default Sidebar



const Sidebar = ({toggleSidebar}) => {
    const location = useLocation();
    const pathname = location.pathname;
    
    // Extract the base path from the current route
    const basePath = pathname.split("/").slice(0, 3).join("/") + "/";

    const [showDropdownId, setShowDropdownId] = useState(null);

    const setDropdownId = (id) => {
        setShowDropdownId(prevId => (prevId === id ? null : id));
    };
console.log(pathname);
    return (
        <div className={`layoutSidebar ${toggleSidebar ? 'hide' : ''}`}>
            <Link to={'/'}>
                <div className='brand'>
                    <img src='/images/logo.png' alt="" />
                </div>
            </Link>
           
            <ul className='sidebarMenu'>
                {menuData && menuData.length !== 0 && menuData.map(item => {
                    const isActive = item.base_paths && item.base_paths.some(path => pathname.startsWith(path));

                    if (item.submenu) {
                        return (
                            <li key={item.label} className={`dropdownMenu ${showDropdownId === item.id || isActive ? 'opened' : ''}`}>
                                <div className='dropdownLabel'>
                                    <Link to={`${item.link}`}>
                                        <Icon icon={item.icon}/> <span>{item.label}</span>
                                    </Link> 
                                    <button onClick={() => setDropdownId(item.id)}><FiChevronDown/></button>
                                </div>
                                <div className={`dropdownList ${showDropdownId === item.id || isActive ? 'show' : ''}`}>
                                    <ul>
                                        {item.submenu.map(subItem => (
                                            <li key={subItem.label} className={pathname === subItem.link ? 'active' : ''}>
                                                <Link to={subItem.link}>{subItem.label}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        );
                    }

                    return (
                        <li key={item.label} className={pathname === item.link ? 'active' : ''}>
                            <Link to={item.link}>
                                <Icon icon={item.icon}/><span>{item.label}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Sidebar;

