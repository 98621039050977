import React, { useState } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import MediaImageDropzone from "../../../components/cmp/MediaImageDropzone";
import { Formik } from "formik";
import { GetMedia, Alerts } from "../../../helpers";

const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/Media Library",
    },
    {
        id: 3,
        label: "Create New",
        link: "/app/media-library/create",
    },
];



const CreateMedia = () => {
    const [isVideo, setVideo] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        let fileType = values.media_file.type;
        fileType = fileType.split('/')[0];
  
     
        // var formData = new FormData();
        // formData.append('media_file', values.media_file);
        // let resp = await GetMedia.createMedia(formData);
        // if(resp && resp.status){
        //   Alerts.alertMessage(resp.message, "top-right", "success");
        //   navigate('/app/media-library');
        // }else{
        //   Alerts.alertMessage(resp.message, "top-right", "error");
        // }
        if (fileType === 'video') {
            if(values.video_poster !== ''){
              var formData = new FormData();
              formData.append('media_file', values.media_file);
              formData.append('video_poster', values.video_poster);
              let resp = await GetMedia.createVideoMedia(formData);
              if(resp && resp.status){
               Alerts.alertMessage(resp.message, "top-right", "success");
               navigate('/app/media-library');
              }else{
               Alerts.alertMessage(resp.message, "top-right", "error");
              }
            }else{
                Alerts.alertMessage("Video poster is required for video media.", "top-right", "error");
            }
           
        } else {
            setVideo(false);
            var formData = new FormData();
            formData.append('media_file', values.media_file);
            let resp = await GetMedia.createMedia(formData);
            if(resp && resp.status){
              Alerts.alertMessage(resp.message, "top-right", "success");
              navigate('/app/media-library');
            }else{
              Alerts.alertMessage(resp.message, "top-right", "error");
            }
        }

        resetForm();
        setSubmitting(false);
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Media
                        </h2>
                        <Link to="/app/media-library" className="btn btn-success">
                            <BiCategory /> Media
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Formik
                            enableReinitialize={true}
                                initialValues={{
                                    media_file: '',
                                    video_poster:''
                                }}
                              
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.media_file) {
                                        errors.media_file = "Media file is required";
                                    }else{
                                        let fileType = values.media_file.type;
                                        fileType = fileType.split('/')[0];
                                        if (fileType === 'video') {
                                        setVideo(true);
                                        }else{
                                            setVideo(false);
                                            values.video_poster=''
                                        }
                                    }
                                  
                                    return errors;
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <MediaImageDropzone setFieldValue={setFieldValue} fieldName="media_file"/>
                                            {errors.media_file && touched.media_file && (
                                                <div className="text-center">
                                                    <span className="help-error ">
                                                        {errors.media_file}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {
                                            isVideo &&  <div className="form-group">
                                            <label>Select your poster</label>
                                           <MediaImageDropzone setFieldValue={setFieldValue} fieldName="video_poster" />
                                           {errors.video_poster && touched.video_poster && (
                                               <div className="text-center">
                                                   <span className="help-error ">
                                                       {errors.video_poster}
                                                   </span>
                                               </div>
                                           )}
                                       </div>
                                        }
                                       
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "Uploading..." : "Upload File"}
                                            </button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateMedia;
