import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../../components/partials/Breadcrumb';
import { BiCategory } from "react-icons/bi";
import { Alerts, GetProducts } from '../../../../helpers';
import MUIDataTable from "mui-datatables";
import { Link } from 'react-router-dom';
import { FaEdit, FaTimes, FaPlus, FaQuestion, FaImages } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import ConfirmationDialog from '../../../../components/cmp/ConfirmationDialog';
import parse from 'html-react-parser';
import { MdSettings } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
import MyModal from '../../../../components/cmp/MyModal';
import { Formik, Field } from 'formik';
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Products",
        link: "/app/products",
    },
];

const ProductReviews = () => {
    const [isLoading, setLoading] = useState(true);
    const [arrData, setData] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [delId, setDelId] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [singleReview, setSingleReview] = useState(null);

    const handleOpenView = (review_id) => {
        const review = arrData.find(el => el.id === review_id);
        if (review) {
            setSingleReview(review);
            setEdit(true);
        }
    }

    const handleCloseView = () => {
        setSingleReview(null);
        setEdit(false);
    }


    const deleteRecord = async (id) => {
        const resp = await GetProducts.deleteReviews(id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            getData();
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error');
        }
        setDelId(null);
        setShowDialog(false);
    }

    const showDialogBox = (id) => {
        setDelId(id);
        setShowDialog(true);
    }

    const getData = async () => {
        let resp = await GetProducts.getProductReviews();
        if (resp && resp.status) {
            setData(resp.reviews);
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };

    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Product',
            sortable: false,
        },
        {
            name: 'nickname',
            sortable: true,
        },
        {
            name: 'Email',
            sortable: true,
        },
        {
            name: 'Ratings',
            sortable: false,
        },
        {
            name: 'Status',
            sortable: false,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];





    const handleUpdateReview = async (values, { setSubmitting }) =>{
         if(singleReview){
            let resp = await GetProducts.updateReviews(singleReview.id, values);
           if(resp && resp.status){
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            handleCloseView();
            getData();
           }
         }
          
    }


    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className='container-fluid'>
                    <div className='page-title'>
                        <h2><BiCategory /> Reviews</h2>

                    </div>
                    {
                        isLoading ? <div className='loader'>
                            <img src='/images/loader.gif' alt='loader' />
                        </div> : <>
                            <MUIDataTable
                                title=""
                                data={arrData.map((item, i) => {

                                    return [
                                        ++i,
                                        <div style={{ minWidth: 260, wordBreak: 'break-all' }}>
                                            {item.product.title}
                                        </div>
                                        ,
                                        item.nickname,
                                        item.email,
                                        <div style={{ minWidth: 250, wordBreak: 'break-all' }}>
                                            <Rating
                                                readonly={true}
                                                disableFillHover={true}
                                                allowFraction={true}
                                                initialValue={(parseInt(item.valueRating) + parseInt(item.featuresRating) + parseInt(item.performanceRating) + parseInt(item.reliabilityRating))/4}
                                                size={22}
                                            />

                                           
                                        </div>
                                        ,

                                        <div className={`badge ${item.status ? "bg-success" : "bg-danger"}`}>{item.status ? 'Active' : 'Draft'}</div>,
                                        <div className={`actions`} style={{ width: "170px" }}>
                                            <button onClick={() => handleOpenView(item.id)} className="btn btn-info btn-sm"><FaEye size={14} /></button> &nbsp;
                                            <button onClick={() => showDialogBox(item.id)} className="btn btn-danger btn-sm"><FaTimes size={14} /></button>
                                        </div>
                                    ]
                                })}
                                columns={columns}
                                options={options}
                            />
                            {showDialog ? <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record." /> : null}
                        </>
                    }
                </div>
            </div>
            {
                isEdit && singleReview !== null && <MyModal show={isEdit} setAddNew={setEdit} title={singleReview.product.title}>
                    <div className='ratings'>
                        <div className='row'>
                            <div className='col-md-5'>
                            <h4 style={{marginBottom:10}}>Ratings</h4>
                        <p><span style={{ minWidth: 105, display: 'inline-block' }}>Value</span> : <Rating
                            readonly={true}
                            disableFillHover={true}
                            allowFraction={true}
                            initialValue={singleReview.valueRating}
                            size={22}
                        /></p>

                        <p><span style={{ minWidth: 110, display: 'inline-block' }}>Features</span>: <Rating
                            readonly={true}
                            disableFillHover={true}
                            allowFraction={true}
                            initialValue={singleReview.featuresRating}
                            size={22}
                        /></p>
                        <p><span style={{ minWidth: 105, display: 'inline-block' }}>Performance</span> : <Rating
                            readonly={true}
                            disableFillHover={true}
                            allowFraction={true}
                            initialValue={singleReview.performanceRating}
                            size={22}
                        /></p>
                        <p><span style={{ minWidth: 105, display: 'inline-block' }}>Reliability</span> : <Rating
                            readonly={true}
                            disableFillHover={true}
                            allowFraction={true}
                            initialValue={singleReview.reliabilityRating}
                            size={22}
                        /></p>
                            </div>
                            <div className='col-md-7'>
                              <h4 style={{marginBottom:10}}>User Details</h4>
                              <p><strong>Nickname : </strong> {singleReview.nickname}</p>
                              <p><strong>Email : </strong> {singleReview.email}</p>
                              <p><strong>Review Title : </strong></p>
                              <p> {singleReview.review_title}</p>
                              <p><strong>Review : </strong></p>
                              <p>{singleReview.review}</p>
                            </div>
                        </div>

                    </div>
                    <Formik
               enableReinitialize
                initialValues={{ status:singleReview.status }}
                validate={(values) => {
                  const errors = {};
                  return errors;
                }}
                onSubmit={handleUpdateReview}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Status</label>
                      <select className='form-control' name='status' defaultValue={values.status}  onChange={handleChange}
                            onBlur={handleBlur}>
                        <option value={true}>Published</option>
                        <option value={false}>Draft</option>
                      </select>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
                </MyModal>
            }
        </>
    )
}

export default ProductReviews;