import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');
const getCurrencies = async () => {
  try {
    let result = await api.get(Apis.GetCurrencies, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getSettings = async () => {
  try {
    let result = await api.get(Apis.GetSettings, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updateVatSettings = async (data) => {
  try {
    let result = await api.post(Apis.UpdateVatSettings, data,{
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};



const exportedObject = {
    getCurrencies,
    getSettings,
    updateVatSettings
};
export default exportedObject;