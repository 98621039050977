
import Modal from 'react-bootstrap/Modal';

const ThemeModal = ({children, show, handleHide, title, isFullscreen=false}) => {
  return (
    <>
      <Modal
        show={show}
        fullscreen={isFullscreen}
        onHide={()=>handleHide(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         {title}
        </Modal.Title>
       </Modal.Header>
        <Modal.Body className='pd-50'>
            {children}
        </Modal.Body>
     
      </Modal>
    </>
  );
}

export default ThemeModal;