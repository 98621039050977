import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Alerts, GetProductCategory } from "../../../helpers";
import Select from 'react-select';
import MUIDataTable from "mui-datatables";
import ConfModal from "../../../components/cmp/ConfModal";
import UpdateVariation from "../../../components/variations/UpdateVariation";
import CreateVariationOption from "../../../components/variations/options/CreateVariationOption";
import { FaEye } from "react-icons/fa";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Variations",
        link: "/app/product/category/variations",
    }
];

const Variations = () => {
    const [categories, setCategories] = useState([]);
    const [variations, setVariations] = useState([]);
    const [groupedVariations, setGroupedVariations] = useState([]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const resp = await GetProductCategory.createVariation(values);
        if (resp && resp.status) {
            getVariations();
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            setSubmitting(false);
            resetForm();
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'danger')
        }
    };

    const getVariations = async () => {
        const resp = await GetProductCategory.getCategoryVariations();
        if (resp && resp.status) {
        const grouped = resp.variations.reduce((acc, item) => {
        const existingCategory = acc.find(
          (group) => group.category_id === item.category_id
        );

        if (existingCategory) {
          existingCategory.children.push({
            id: item.id,
            name: item.name,
            input_type:item.input_type,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          });
        } else {
          acc.push({
            category_id: item.category_id,
            title: item.variation_category.title,
            children: [
              {
                id: item.id,
                name: item.name,
                input_type:item.input_type,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
              },
            ],
          });
        }
        return acc;
      }, []);

            setGroupedVariations(grouped);
            setVariations(resp.variations);
        }
    }

    const getCategories = async () => {
        const resp = await GetProductCategory.getAllCategories();
        if (resp && resp.status) {
            const options = resp.data.filter(item => item.parent_id === null).map((item) => ({
                value: item.id,
                label: item.title,
            }));
            setCategories(options);
        }
    };

    useEffect(() => {
        getCategories();
        getVariations();
    }, []);



    const handleDelete = async (id) => {
        const resp = await GetProductCategory.deleteVariation(id);
        if (resp && resp.status) {
            getVariations();
            Alerts.alertMessage(resp.message, 'top-right', 'success');

        } else {
            Alerts.alertMessage('Something went wrong !', 'top-right', 'danger')
        }
    }



    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };


    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Category',
            sortable: true,
        },
        {
            name: 'Variations',
            sortable: true,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Category Variation
                        </h2>
                        <Link to="/app/product/categories" className="btn btn-success">
                            <BiCategory /> Variations
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <Formik
                                        initialValues={{
                                            category_id: "",
                                            name: "",
                                            input_type:''
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.category_id) {
                                                errors.category_id = "Category is required";
                                            }
                                            if (!values.name) {
                                                errors.name = "Variation name is required";
                                            }
                                            if (!values.input_type) {
                                                errors.input_type = "Variation input type is required";
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>

                                                <div className="form-group">
                                                    <label htmlFor="category_id">Category</label>
                                                    <Select
                                                        id="category_id"
                                                        name="category_id"
                                                        placeholder="Select a Category"
                                                        options={categories}
                                                        isClearable
                                                        onChange={(selectedOption) =>
                                                            setFieldValue("category_id", selectedOption ? selectedOption.value : null)
                                                        }
                                                        onBlur={() => handleBlur({ target: { name: "category_id" } })}
                                                    />
                                                    {errors.category_id && touched.category_id && (
                                                        <span className="help-error">{errors.category_id}</span>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                    {errors.name && touched.name && (
                                                        <span className="help-error">{errors.name}</span>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Input Type</label>
                                                    <select className="form-control" 
                                                        name="input_type"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.input_type}
                                                        >
                                                        <option value={''}>---Select---</option>
                                                        <option value={'text'}>Text</option>
                                                        <option value={'number'}>Number</option>
                                                        <option value={'textarea'}>Textarea</option>
                                                    </select>
                                                    {errors.input_type && touched.input_type && (
                                                        <span className="help-error">{errors.input_type}</span>
                                                    )}
                                                </div>


                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-md-8">
                                   <MUIDataTable
                                        title="Variations"
                                        data={groupedVariations.map((item, i) => {

                                            return [
                                                ++i,
                                                item.title,
                                                item.children.map((varItem) => varItem.name).join(", "),
                                                <div className={`actions`} style={{ width: "150px" }}>
                                                    <UpdateVariation category_id={item.category_id} variation={item} getVariations={getVariations}/> &nbsp;
                                                    {/* <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={handleDelete} /> &nbsp; */}
                                                    {/* <CreateVariationOption variation={item} /> */}
                                                  {/*  <Link className="btn btn-success btn-sm" to={`/app/product/category/variations/option/${item.id}`}><FaEye size={18}/></Link> */}
                                                </div>
                                            ]
                                        })}
                                        columns={columns}
                                        options={options}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Variations;
