import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { GetProductCategory, Alerts, GetProducts } from "../../../helpers";
import CKEditorField from "../../../components/cmp/CKEditorField";
import Select from "react-select";
import MediaLibraryPopup from "../../../components/cmp/MediaLibraryPopup";
import { FiEdit } from "react-icons/fi";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Create New",
        link: "/app/product/categories/create",
    },
];

const CreateProduct = () => {
    const navigate = useNavigate();
    const [categoryOptions, setCategoriesOption] = useState([]);
    const [showMedia, setShowMedia] = useState(false);
    const [product_thumb, setProductThumb] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const getCategories = async () => {
        let resp = await GetProductCategory.getAllCategories();
        if (resp && resp.status) {

            const optionsValue = resp.data.map(item => ({
                value: item.id,
                label: item.title
            }));
            setCategoriesOption(optionsValue);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const handleSelectImage = (image) => {
        setProductThumb(image);
        setShowMedia(false);
    }





    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const data = {
            title: values.title,
            short_description: values.short_description,
            description: values.description,
            specification: values.specification,
            regular_price: values.regular_price || 0.00,
            sale_price: values.sale_price || 0.00,
            tax_status: values.tax_status,
            best_seller: values.best_seller ? 1 : 0,
            sku: values.sku,
            stock: values.stock || 0,
            product_image: values.product_image,
            status: values.status,
            isVariation: values.isVariation ? 1 : 0,
            variation_image: values.variation_image ? 1 : 0,
            categories: values.categories
        }
        // console.log(data);
        let resp = await GetProducts.createProduct(data);
        if (resp && resp.status) {
            resetForm();
            Alerts.alertMessage(resp.message, "top-right", "success");
            navigate(`/app/products/manage/${resp.product_id}`);
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }

        setSubmitting(false);
    };

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Product
                        </h2>
                        <Link to="/app/products" className="btn btn-success">
                            <BiCategory /> Products
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Tabs
                                defaultActiveKey="product_details"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="product_details" title="Product Details">
                                    <Formik
                                        initialValues={{
                                            title: "",
                                            short_description: "",
                                            description: "",
                                            specification: "",
                                            regular_price: "",
                                            sale_price: "",
                                            tax_status: 0,
                                            best_seller: false,
                                            sku: "",
                                            stock: "",
                                            product_image: '',
                                            status: 0,
                                            categories: [],
                                            isVariation: false,
                                            variation_image: false
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.title) {
                                                errors.title = "Title is required";
                                            }

                                            if (!values.isVariation) {

                                                if (!values.short_description) {
                                                    errors.short_description = "Short description is required";
                                                }
                                                if (!values.description) {
                                                    errors.description = "Description is required";
                                                }
                                                // if (!values.specification) {
                                                //     errors.specification = "Specification is required";
                                                // }

                                                if (!values.regular_price) {
                                                    errors.regular_price = "Regular price is required";
                                                } else if (isNaN(values.regular_price)) {
                                                    errors.regular_price = "Regular price is number or decimal value";
                                                }

                                                if (!values.sale_price) {
                                                    errors.sale_price = "Sale price is required";
                                                } else if (isNaN(values.sale_price)) {
                                                    errors.sale_price = "Sale price is number or decimal value";
                                                }

                                                if (!values.sku) {
                                                    errors.sku = "SKU is required";
                                                }
                                                if (!values.stock) {
                                                    errors.stock = "Stock is required";
                                                }
                                            }

                                            if (!values.product_image) {
                                                errors.product_image = "Product image is required";
                                            }

                                            return errors;
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className={`col-md-8`}>
                                                        <div className="form-group">
                                                            <label>Product Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.title}
                                                            />
                                                            {errors.title && touched.title && (
                                                                <span className="help-error">{errors.title}</span>
                                                            )}
                                                        </div>
                                                        <div className="form-group row pd-10">
                                                            <div className="form-check-custom col-md-6 mt-0">
                                                                {/* <input type="checkbox" value={values.isVariation} id="isVariation" name="isVariation" onChange={handleChange}
                                                                    onBlur={handleBlur} /> */}
                                                                <input
                                                                    type="checkbox"
                                                                    id="isVariation"
                                                                    name="isVariation"
                                                                    checked={values.isVariation}
                                                                    onChange={() =>{ 
                                                                        setFieldValue("isVariation", !values.isVariation)
                                                                        setFieldValue("variation_image", !values.variation_image);
                                                                    }}
                                                                />
                                                                <label htmlFor="isVariation">
                                                                    Variable Product
                                                                </label>
                                                            </div>
                                                            { values.isVariation && <div className="form-check-custom mt-0 col-md-6">
                                                                <input type="checkbox" id="variation_image" name="variation_image"   checked={values.variation_image}
                                                                    onChange={() => setFieldValue("variation_image", !values.variation_image)}/>
                                                                <label htmlFor="variation_image">
                                                                   Variation Wise Image
                                                                </label>
                                                            </div>}
                                                           
                                                        </div>
                                                        <div className="form-group">
                                                                    <label>Short description</label>
                                                                    <Field
                                                                        name="short_description">
                                                                        {({ field, form }) => (
                                                                            <CKEditorField field={field} form={form} />
                                                                        )}
                                                                    </Field>


                                                                    {errors.short_description &&
                                                                        touched.short_description && (
                                                                            <span className="help-error">
                                                                                {errors.short_description}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                        {
                                                            !values.isVariation && <>
                                                               
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <Field name="description">
                                                                        {({ field, form }) => (
                                                                            <CKEditorField field={field} form={form} />
                                                                        )}
                                                                    </Field>

                                                                    {errors.description && touched.description && (
                                                                        <span className="help-error">
                                                                            {errors.description}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {/* <div className="form-group">
                                                            <label>Product Specification</label>
                                                            <Field name="specification">
                                                                {({ field, form }) => (
                                                                    <CKEditorField field={field} form={form} />
                                                                )}
                                                            </Field>
                                                            {errors.specification && touched.specification && (
                                                                <span className="help-error">
                                                                    {errors.specification}
                                                                </span>
                                                            )}
                                                        </div> */}
                                                            </>
                                                        }

                                                    </div>
                                                    <div className={`col-md-4`}>
                                                        <div className="form-group">
                                                            <label>Product Thumbnail</label>
                                                            <div className="dropzone-box">

                                                                {
                                                                    product_thumb !== '' && <img src={product_thumb.path} alt="product_thumbnail" />
                                                                }
                                                                {
                                                                    product_thumb !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                                                        {/* <p>Drop files to upload</p>
                                                                        <p>or</p> */}
                                                                        <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                                                                    </>
                                                                }
                                                            </div>


                                                            {errors.product_image && touched.product_image && (
                                                                <span className="help-error">
                                                                    {errors.product_image}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {
                                                            !values.isVariation && <>

                                                                <div className="form-group row">
                                                                    <div className="col-md-6">
                                                                        <label>Regular Price</label>
                                                                        <div className="priceBox">
                                                                            <div className="priceIcon">£</div>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="regular_price"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.regular_price}
                                                                                placeholder="0.00"
                                                                            />
                                                                        </div>

                                                                        {errors.regular_price && touched.regular_price && (
                                                                            <span className="help-error">
                                                                                {errors.regular_price}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Sale Price</label>
                                                                        <div className="priceBox">
                                                                            <div className="priceIcon">£</div>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="sale_price"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.sale_price}
                                                                                placeholder="0.00"
                                                                            />
                                                                        </div>
                                                                        {errors.sale_price && touched.sale_price && (
                                                                            <span className="help-error">
                                                                                {errors.sale_price}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <div className="col-md-6">
                                                                        <label>SKU</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="sku"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.sku}
                                                                        />
                                                                        {errors.sku && touched.sku && (
                                                                            <span className="help-error">{errors.sku}</span>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Total Stock</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="stock"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.stock}
                                                                        />
                                                                        {errors.stock && touched.stock && (
                                                                            <span className="help-error">{errors.stock}</span>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                        <div className="form-group row">
                                                            <div className="col-md-6">
                                                                <label>Tax Status</label>
                                                                <select className="form-control" name="tax_status" onChange={handleChange}
                                                                    onBlur={handleBlur} value={values.tax_status}>
                                                                    <option value={0}>No</option>
                                                                    <option value={1}>Yes</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">

                                                                <div className="form-check-custom">
                                                                    <input type="checkbox" value={values.best_seller} id="best_sale" name="best_seller" onChange={handleChange}
                                                                        onBlur={handleBlur} />
                                                                    <label htmlFor="best_sale">
                                                                        Best Saller
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Categories</label>
                                                            <Field name="categories">
                                                                {({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        value={categoryOptions.filter((option) =>
                                                                            values.categories.includes(option.value)
                                                                        )}
                                                                        onChange={(selectedOptions) =>
                                                                            setFieldValue(
                                                                                "categories",
                                                                                selectedOptions.map(
                                                                                    (option) => option.value
                                                                                )
                                                                            )
                                                                        }
                                                                        options={categoryOptions}
                                                                        placeholder="Select categories"
                                                                        isMulti
                                                                        className="select2"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <select className="form-control" name="status" onChange={handleChange}
                                                                onBlur={handleBlur} value={values.status}>
                                                                <option value={1}>Published</option>
                                                                <option value={0}>Draft</option>
                                                            </select>
                                                        </div>
                                                        <div className="sbt-product">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-block"
                                                                disabled={isSubmitting}
                                                            >
                                                                {isSubmitting ? "Please wait..." : "Save"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    showMedia && <MediaLibraryPopup  show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image" />
                                                }
                                            </form>
                                        )}
                                    </Formik>
                                </Tab>
                                <Tab eventKey="variations" title="Variations" disabled>
                                    Variations
                                </Tab>
                                <Tab eventKey="specification" title="Specification" disabled>
                                    specification
                                </Tab>
                                <Tab eventKey="other_details" title="Images & Videos" disabled>
                                    Images & Videos
                                </Tab>
                            </Tabs>



                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateProduct;
