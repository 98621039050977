import React, { useState } from 'react';
import '../../assets/css/modal.css';
import { MdClose } from "react-icons/md";
const CustomModal = ({ show, handleClose, children, productVariation }) => {
  if (!show) {
    return null; 
  }

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">
        <div className="modal-header">
          <h4>
          {productVariation?.variation_content !== null ? 'Update Variation Content' : 'Add Variation Content'} &nbsp; - &nbsp;
          {productVariation?.variations}
          </h4>
          
          <button className="close-btn" onClick={()=>handleClose()}><MdClose/></button>
        </div>
        <div className="custom-modal-body">
        {children}
        </div>
       
      </div>
    </div>
  );
};

export default CustomModal;
