import React, { useEffect, useState } from 'react';
import ConfModal from '../../cmp/ConfModal';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { GetProducts } from '../../../helpers';
import { RiDragMove2Fill } from "react-icons/ri";
import { FiEye } from 'react-icons/fi';
import MyModal from '../../cmp/MyModal';
const DragHandle = SortableHandle(() => (
    <span style={{ cursor: 'grab', marginRight: '10px' }}><RiDragMove2Fill size={20} color='#adadad' /></span>
));

const SortableItem = SortableElement(({ item, index, indexValue, handleDeleteImage, handleView }) => {
    return (
        <tr>
            <td>  <DragHandle /> {indexValue + 1}</td>
            <td>{item.media_file.filename}</td>
            <td>{item.media_file.file_type}</td>
            <td>
                {item.media_file.file_type === 'image' ? (
                    <img src={item.media_file.path} alt="img" style={{ width: 40, height: 40 }} />
                ) : item.media_file.file_type === 'video' ? (
                    <img src={'/images/video.svg'} alt="img" style={{ width: 40, height: 40 }} />
                ) : item.media_file.file_type === 'text' ? (
                    <img src={'/images/txt-file.png'} alt={''} style={{ width: 40 }} />
                ) : null}
            </td>
            <td> <button className="btn btn-success btn-sm" onClick={() => handleView(item)}><FiEye size={14} /></button> &nbsp;
                <ConfModal
                    message={'Are you sure you want to delete this record'}
                    delId={item.id}
                    handleDelete={handleDeleteImage}
                /> 
            </td>
        </tr>
    );
});

const SortableList = SortableContainer(({ items, handleDeleteImage, handleView }) => {
    return (
        <tbody>
            {items.map((item, index) => {
                return (
                    <SortableItem
                        key={item.id}
                        index={index}
                        indexValue={index}
                        item={item}
                        handleDeleteImage={handleDeleteImage}
                        handleView={handleView}
                    />
                );
            })}
        </tbody>
    );
});

const ProductGallery = ({ mediaFiles, handleDeleteImage }) => {
    const [data, setData] = useState([]);
        const [selectedMedia, setSelectedMedia] = useState(null);
        const [showMediaModal, setShowMediaModal] = useState(false);
    
        const handleView = (value) => {
            setSelectedMedia(value);
            setShowMediaModal(true)
        }
        const handleCloseView = () => {
            setSelectedMedia(null);
            setShowMediaModal(false);
        }


    useEffect(() => {
        setData(mediaFiles);
    }, [mediaFiles])
    const handleDropEnd = async ({ oldIndex, newIndex }) => {
        const updatedItems = [...data];
        const [movedItem] = updatedItems.splice(oldIndex, 1);
        updatedItems.splice(newIndex, 0, movedItem);
        setData(updatedItems);
        const newIndexArray = updatedItems.map((el) => {
            return el.id
        })
        const resp = await GetProducts.updateProductGalleryOrder({
            productImageIds: newIndexArray
        });
        console.log('resp', resp);
    };

    return (
        <>
            <table className="table table-hover table-bordered">
                <thead>
                    <tr class="table-active">
                        <th>#</th>
                        <th>File Name</th>
                        <th>File Type</th>
                        <th>Files</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <SortableList
                    items={data}
                    onSortEnd={handleDropEnd}
                    handleDeleteImage={handleDeleteImage}
                    handleView={handleView}
                    useDragHandle
                />
            </table>
            {
                selectedMedia !== null && <MyModal show={showMediaModal} setAddNew={handleCloseView} title={selectedMedia.media_file.filename}>
                    <div className='text-center'>
                    {
                        selectedMedia.media_file.file_type === 'image' ? <img src={selectedMedia.media_file.path} alt="product_thumbnail" style={{ width: '100%', maxWidth:400, margin:"0 auto"}} /> : selectedMedia.media_file.file_type === 'video' ?
                            <video width={'100%'} controls={true}>
                                <source src={selectedMedia.media_file.path} type={selectedMedia.media_file.type} />
                            </video>
                            : selectedMedia.media_file.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: '100%' }} />
                    }
                    </div>
                   
                </MyModal>
            } 

        </>

    );
};

export default ProductGallery;
