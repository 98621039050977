
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./components/Layouts/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
//Dashboard Pages
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from './pages/Dashboard/Users/profile/profile';
import ChangePassword from './pages/Dashboard/Users/password/ChangePassword';
import User from './pages/Dashboard/Users/User';
import CreateCategory from './pages/Dashboard/Products/Category/CreateCategory';
import Categories from './pages/Dashboard/Products/Category/Categories';
import EditCategory from './pages/Dashboard/Products/Category/EditCategory';
import CategoryFaq from './pages/Dashboard/Products/Category/CategoryFaq';
import CreatePostCategory from './pages/Dashboard/Post/Category/CreatePostCategory';
import PostCategories from './pages/Dashboard/Post/Category/PostCategories';
import EditPostCategory from './pages/Dashboard/Post/Category/EditPostCategory';
import Post from './pages/Dashboard/Post/Post';
import CreatePost from './pages/Dashboard/Post/CreatePost';
import EditPost from './pages/Dashboard/Post/EditPost';
import CreateProduct from './pages/Dashboard/Products/CreateProduct';
import Products from './pages/Dashboard/Products/Products';
import EditProduct from './pages/Dashboard/Products/EditProduct';
import MediaLibrary from './pages/Dashboard/MediaLibrary/MediaLibrary';
import CreateMedia from './pages/Dashboard/MediaLibrary/CreateMedia';
import ProductSlider from './pages/Dashboard/Products/ProductSlider';
import Variations from "./pages/Dashboard/Products/Variations";
import ProductFaq from "./pages/Dashboard/Products/Faqs/CreateFaq";
import Specification from "./pages/Dashboard/specification/Specification";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import { useAuth } from "./context/AuthContext";
import VariationOption from "./pages/Dashboard/Products/VariationOption";
import ProductVariation from "./pages/Dashboard/Products/ProductVariation";
import ProductDetails from "./pages/Dashboard/Products/ProductDetails";
import CreateVariation from "./pages/Dashboard/Variations/CreateVariation";
import CreateCategoryVariation from "./pages/Dashboard/Variations/CreateCategoryVariation";
import CurrencySetup from "./pages/Dashboard/settings/CurrencySetup";
import AddVariationContent from "./pages/Dashboard/Products/AddVariationContent";
import Settings from "./pages/Dashboard/settings/Settings";
import ProductReviews from "./pages/Dashboard/Products/Reviews/ReviewList";
import Orders from "./pages/Dashboard/Orders/Orders";
import InventoryProducts from "./pages/Dashboard/Inventory/InventoryList";
function App() {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={isAuthenticated() ? <Navigate to="/app/dashboard" /> : <Navigate to="/login" />} />
        <Route path="/app" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route path="/app/dashboard" element={<Dashboard />} />
          <Route path="/app/profile" element={<Profile/>} />
          <Route path="/app/update-password" element={<ChangePassword/>} />
          <Route path="/app/users" element={<User/>} />
          <Route path="/app/product/categories" element={<Categories/>} />
          <Route path="/app/product/categories/create" element={<CreateCategory/>} />
          <Route path="/app/product/categories/edit/:id" element={<EditCategory/>} />
          <Route path="/app/product/categories/faq/:id" element={<CategoryFaq/>} />
          <Route path="/app/product/create" element={<CreateProduct/>} />
          <Route path="/app/products" element={<Products/>} />
          <Route path="/app/product/edit/:id" element={<EditProduct/>} />
          <Route path="/app/products/manage/:id" element={<ProductDetails/>} />
          <Route path="/app/product/media/:product_id" element={<ProductSlider/>} />
          <Route path="/app/product/variation/:product_id" element={<ProductVariation/>} />
          <Route path="/app/product/category/variation" element={<CreateCategoryVariation/>} />
          <Route path="/app/product/category/variations" element={<Variations/>} />
          <Route path="/app/product/category/variations/option/:id" element={<VariationOption/>} />
          <Route path="/app/variation/create" element={<CreateVariation/>} />
          <Route path="/app/currency/setup" element={<CurrencySetup/>} />
          <Route path="/app/ck/testing" element={<AddVariationContent/>} />
          <Route path="/app/product/specification" element={<Specification/>} />
          <Route path="/app/product/faqs/:id" element={<ProductFaq/>} />
          <Route path="/app/product/reviews" element={<ProductReviews/>} />
          <Route path="/app/orders" element={<Orders/>} />
          <Route path="/app/inventory" element={<InventoryProducts/>} />
          
          
          

          <Route path="/app/media-library" element={<MediaLibrary/>} />
          <Route path="/app/media-library/create" element={<CreateMedia/>} />

          <Route path="/app/post/categories" element={<PostCategories/>} />
          <Route path="/app/post/categories/create" element={<CreatePostCategory/>} />
          <Route path="/app/post/categories/edit/:id" element={<EditPostCategory/>} />
          <Route path="/app/posts" element={<Post/>} />
          <Route path="/app/posts/create" element={<CreatePost/>} />
          <Route path="/app/posts/edit/:id" element={<EditPost/>} />
          <Route path="/app/settings" element={<Settings/>} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
