import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import CustomModal from '../../../components/cmp/CustomModal';
const AddVariationContent = () => {
    const [show, setShow] = useState(false);
    const [value, setValues] = useState('')
    const handleEditorChange = (content, editor) => {
        setValues(content);
      };
      useEffect(() => {
        const handler = (e) => {
          if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
          }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
      }, []);
  return (
    <div>
        <button onClick={()=>setShow(true)}>Show Dialog</button>
        <CustomModal show={show} handleClose={setShow}>
         
        <Editor
              apiKey="rueh561lhycrgpckg0wk1bqtbzcjvx1g6vrap3phnnsarue9" // Use your API key here (or leave out for free version)
              value={value}
              init={{
                height: 500,
                menubar: true,
                menu: {
                  happy: { title: 'Happy', items: 'code' }
                },
                plugins: [
                  'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                  'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                  'media', 'table', 'emoticons', 'help'
                ],
               
                toolbar:
                'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code', // Add 'code' here for the "View Source" button
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              setup(editor) {
                editor.ui.registry.addButton('codedfd', {
                    onAction: () => {
                        console.log('Source Code button clicked!');
                        alert('Source Code view opened!');
                        // Additional logic can go here
                    },
                });
              },
              }}
              onEditorChange={handleEditorChange}
            />
        </CustomModal>
        
    </div>
  )
}

export default AddVariationContent