import Modal from "react-bootstrap/Modal";
import { Formik, Field } from "formik";
import CKEditorField from "../../cmp/CKEditorField";
import { GetProducts, Alerts } from "../../../helpers";
import CustomModal from "../../cmp/CustomModal";
const AddProductContent = ({
    show,
    handleClose,
    title = "Add Product Details",
    productVariation
}) => {
    const handleSubmitContent = async (values) => {
       

        if(productVariation.variation_content === null){
            values.product_variation_id=productVariation.id;
            const resp = await GetProducts.createProductVariationContent(values);
            if(resp && resp.status){
                Alerts.alertMessage(resp.message, "top-right", "success");
                handleClose();
            }else{
                Alerts.alertMessage(resp.message, "top-right", "error");
            }
        }else{
            const resp = await GetProducts.updateProductVariationContent(values, productVariation.variation_content.id);
            if(resp && resp.status){
                Alerts.alertMessage(resp.message, "top-right", "success");
                handleClose();
            }else{
                Alerts.alertMessage(resp.message, "top-right", "error");
            }
        }

        
       
    }

    return (
        <>
            <CustomModal
                show={show}
                handleClose={handleClose}
                productVariation={productVariation}
            >
                <Modal.Body className="pd-50 pt-3">
                    <Formik
                    enableReinitialize={true}
                        initialValues={{
                            title: productVariation.variation_content !== null ? productVariation.variation_content.title :"",
                            short_description:productVariation.variation_content !== null ? productVariation.variation_content.short_description :"",
                            description: productVariation.variation_content !== null ? productVariation.variation_content.description :"",
                            specification:productVariation.variation_content !== null ? productVariation.variation_content.specification :"",
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.title) {
                                errors.title = "Title is required";
                            }
                            if (!values.short_description) {
                                errors.short_description = "Short description is required";
                            }
                            if (!values.description) {
                                errors.description = "Description is required";
                            }
                            // if (!values.specification) {
                            //     errors.specification = "Specification is required";
                            // }
                            return errors;
                        }}
                        onSubmit={handleSubmitContent}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Product Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                    />
                                    {errors.title && touched.title && (
                                        <span className="help-error">{errors.title}</span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Short description</label>
                                    <Field name="short_description">
                                        {({ field, form }) => (
                                            <CKEditorField field={field} form={form} />
                                        )}
                                    </Field>

                                    {errors.short_description && touched.short_description && (
                                        <span className="help-error">
                                            {errors.short_description}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <Field name="description">
                                        {({ field, form }) => (
                                            <CKEditorField field={field} form={form} />
                                        )}
                                    </Field>

                                    {errors.description && touched.description && (
                                        <span className="help-error">{errors.description}</span>
                                    )}
                                </div>
                                {/* <div className="form-group">
                                    <label>Product Specification</label>
                                    <Field name="specification">
                                        {({ field, form }) => (
                                            <CKEditorField field={field} form={form} />
                                        )}
                                    </Field>
                                    
                                    {errors.specification && touched.specification && (
                                        <span className="help-error">{errors.specification}</span>
                                    )}
                                </div> */}
                                <div className="sbt-product">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Please wait..." : "Save"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </CustomModal>
        </>
    );
};

export default AddProductContent;
