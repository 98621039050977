import api from '../../ApiConfig';
import { Apis } from '../../config';


const getAllOrders = async () => {
  try {
    var token = localStorage.getItem('userToken');
    let result = await api.get(Apis.GetAllOrders, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    return null;
  }
};
const getSingleOrders = async (id) => {
    try {
      var token = localStorage.getItem('userToken');
      let result = await api.get(Apis.GetSingleOrders+id, {
        headers: {
          'x-access-token': token
        }
      });
      if (result.data.error) {
        return null;
      }
      return result.data;
  
    } catch (error) {
      return null;
    }
  };
const updateOrderStatus = async (id, data) => {
  try {
    var token = localStorage.getItem('userToken');
    let result = await api.put(Apis.UpdateOrderStatus+id, data, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
}
const updatePaymentStatus = async (id, data) => {
    try {
      var token = localStorage.getItem('userToken');
      let result = await api.put(Apis.UpdatePaymentStatus+id, data, {
        headers: {
          'x-access-token': token
        }
      });
      if (result.data.error) {
        return null;
      }
      return result.data;
  
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return null;
    }
  }



const exportedObject = {
    getAllOrders,
    getSingleOrders,
    updateOrderStatus,
    updatePaymentStatus
};
export default exportedObject;