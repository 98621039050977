import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { FaShoppingBasket } from "react-icons/fa";
import { Alerts, GetOrders } from '../../../helpers';
import MUIDataTable from "mui-datatables";
import ThemeModal from '../../../components/cmp/ThemeModal';
import { FaEye } from "react-icons/fa";
import Card from 'react-bootstrap/Card';

const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Orders",
    link: "/app/orders",
  }
];



const options = {
  filter: false,
  download: false,
  print: false,
  selectableRows: false
};

const columns = [
  {
    name: 'SL'
  },
  {
    name: 'Order ID',
    sortable: true,
  },
  {
    name: 'Sub Total',
  },
  {
    name: 'Delivery Charge',
  },
  {
    name: 'Vat Charge',
  },
  {
    name: 'Total Amount',
  },
  {
    name: 'Order Status',
  },
  {
    name: 'Payment Status',
  },
  {
    name: 'Actions',
    sortable: false,
  },
];

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [orderModal, setOrderModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');


  const getOrders = async () => {
    const resp = await GetOrders.getAllOrders();
    if (resp && resp.status) {
      setLoading(false);
      setOrders(resp.orders);
    }
  }

  useEffect(() => {
    getOrders();
  }, [])

  const handleOpenSingleOrder = (id) => {
    const fOrder = orders.find(el => el.id === id);
    if (fOrder) {
      setOrderStatus(fOrder.order_status);
      setPaymentStatus(fOrder.payment_status);
      setOrder(fOrder);
      setOrderModal(true);
    }
  }
  const handleCloseSingleOrder = () => {
    setOrder(null);
    setOrderModal(false);
  }

const handleChangeOrderStatus = (e)=>{
    setOrderStatus(e.target.value)
}

const handleChangePaymentStatus = (e)=>{
  setPaymentStatus(e.target.value)
}

const handleUpdateOrderStatus = async (id)=>{
  const resp = await GetOrders.updateOrderStatus(id, {order_status:orderStatus});
  if(resp && resp.status){
    getOrders();
    Alerts.alertMessage(resp.message, 'top-right', 'success');
  }

}
const handleUpdatePaymentStatus = async (id)=>{
  const resp = await GetOrders.updatePaymentStatus(id, {payment_status:paymentStatus});
  if(resp && resp.status){
    getOrders();
    Alerts.alertMessage(resp.message, 'top-right', 'success');
  }
}
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className='container-fluid'>
          <div className='page-title'>
            <h2>
              <FaShoppingBasket />
              Orders</h2>
          </div>
          {
            isLoading ? <div className='loader'>
              <img src='/images/loader.gif' alt='loader' />
            </div> : <>
              <MUIDataTable
                title=""
                data={orders.map((item, i) => {

                  return [
                    ++i,
                    item.order_number,
                    item.subtotal,
                    item.delivery_charge,
                    item.vat,
                    item.total_amount,
                    <div className={`badge ${item.order_status === 'Success' ? "bg-success" : item.order_status === 'Failed' ? "bg-danger" : item.order_status === 'Pending' ? "bg-warning" : ""}`}>{item.order_status}</div>,
                    <div className={`badge ${item.payment_status === 'Paid' ? "bg-success" : item.payment_status === 'Failed' ? "bg-danger" : item.payment_status === 'Pending' ? "bg-warning" : ""}`}>{item.payment_status}</div>,
                    <div className={`actions`} style={{ width: "150px" }}>
                      <button onClick={() => handleOpenSingleOrder(item.id)} className="btn btn-info btn-sm"><FaEye size={14} /></button>
                    </div>
                  ]
                })}
                columns={columns}
                options={options}
              />

              {
                orderModal && order !== null && <ThemeModal show={orderModal} handleHide={handleCloseSingleOrder} title={'Order Details'} isFullscreen={true}>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-5'>
                       
                        <Card style={{minHeight:'100%'}}>
                          <Card.Header><h5>Order Details</h5></Card.Header>
                          <Card.Body>
                             <table className='table table-ft-15'>
                                 <tbody>
                                   <tr>
                                     <th>Order Number</th>
                                     <td>{order.order_number}</td>
                                   </tr>
                                   <tr>
                                     <th>Sub Total</th>
                                     <td>£{order.subtotal}</td>
                                   </tr>
                                   <tr>
                                     <th>Vat Charges</th>
                                     <td>£{order.vat}</td>
                                   </tr>
                                   <tr>
                                     <th>Delivery Charges</th>
                                     <td>£{order.delivery_charge}</td>
                                   </tr>
                                   <tr>
                                     <th>Total Amount</th>
                                     <td>£{order.total_amount}</td>
                                   </tr>
                                   <tr>
                                     <th>Order Status</th>
                                     <td>  <div className={`badge ${order.order_status === 'Success' ? "bg-success" : order.order_status === 'Failed' ? "bg-danger" : order.order_status === 'Pending' ? "bg-warning" : ""}`}>{order.order_status}</div></td>
                                   </tr>
                                   <tr>
                                   <th>Payment Status</th>
                                     <td><div className={`badge ${order.payment_status === 'Paid' ? "bg-success" : order.payment_status === 'Failed' ? "bg-danger" : order.payment_status === 'Pending' ? "bg-warning" : ""}`}>{order.payment_status}</div></td>
                                   </tr>
                                   
                                 </tbody>
                             </table>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className='col-md-7'>
                        <Card>
                          <Card.Header><h5>Order Items</h5></Card.Header>
                          <Card.Body>
                              <table className='table table-ft-15'>
                                  <thead>
                                      <tr>
                                          <th>#SL</th>
                                          <th>SKU</th>
                                          <th>Name</th>
                                          <th>Unit Price</th>
                                          <th>Vat Price</th>
                                          <th>Total Price</th>
                                          <th>Image</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                     {
                                      order.ordered_items &&  order.ordered_items.length > 0 && order.ordered_items.map((item, i)=>{
                                        return <tr key={i}>
                                          <td>{++i }</td>
                                          <td>{item.pro_sku || item.var_sku }</td>
                                          <td>{item.pro_sku || item.var_sku }</td>
                                          <td>{item.unit_price}</td>
                                          <td>{item.vat}</td>
                                          <td>{item.total_price}</td>
                                          <td>{item.pro_sku !== null ? <img src={item.product.product_thumbnail.path}  alt='image' width={50}/> : item.variation_product && item.variation_product.variation_galleries && item.variation_product.variation_galleries.length > 0 ?  <img src={item.variation_product.variation_galleries[0].media_file.path}  alt='image' width={50}/> : null}</td>
                                        </tr>
                                      })
                                     }
                                    
                                  </tbody>
                              </table>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                    <div className='row' style={{marginTop:25}}>
                    <div className='col-md-5'>
                          <Card>
                             <Card.Header><h5>Manage Order</h5></Card.Header>
                             <Card.Body>
                                <div className='row'>
                                    <div className='col-md-9'>
                                    <div className='form-group'>
                                    <label>Order Status</label>
                                     <select className='form-control' defaultValue={orderStatus} onChange={handleChangeOrderStatus}>
                                        <option value={'Pending'}>Pending</option>
                                        <option value={'Success'}>Success</option>
                                        <option value={'Failed'}>Failed</option>
                                     </select>
                                  </div>
                                    </div>
                                    <div className='col-md-3'>
                                       <button className='btn btn-primary theme-btn mt-30' onClick={()=>handleUpdateOrderStatus(order.id)}>Update</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-9'>
                                    <div className='form-group'>
                                    <label>Payment Status</label>
                                     <select className='form-control' defaultValue={paymentStatus} onChange={handleChangePaymentStatus}>
                                        <option value={'Pending'}>Pending</option>
                                        <option value={'Paid'}>Paid</option>
                                        <option value={'Failed'}>Failed</option>
                                     </select>
                                  </div>
                                    </div>
                                    <div className='col-md-3'>
                                       <button className='btn btn-primary theme-btn mt-30' onClick={()=>handleUpdatePaymentStatus(order.id)}>Update</button>
                                    </div>
                                </div>
                             </Card.Body>
                          </Card>
                         
                      </div>
                      <div className='col-md-7'>
                       <div style={{display:'flex', alignItems:'align-items-center'}}>
                             <Card>
                             <Card.Header><h5>Shipping Address</h5></Card.Header>
                             <Card.Body>
                             <p style={{fontSize:15}}><strong>{order.shipping_address.full_name}</strong></p>
                              <p style={{fontSize:15}}>{order.shipping_address.address_line_1}, {order.shipping_address.address_line_2 !== null && `,${order.shipping_address.address_line_2}`}{order.shipping_address.address_line_3 !== null && `,${order.shipping_address.address_line_3}`} {order.shipping_address.city}, {order.shipping_address.state}, {order.shipping_address.pin_code}, {order.shipping_address.country}</p>
                              <p style={{fontSize:15}}>Phone number: {order.shipping_address.phone_number}</p>
                             </Card.Body>
                          </Card>
                          <Card>
                             <Card.Header><h5>Billing Address</h5></Card.Header>
                             <Card.Body>
                             <p style={{fontSize:15}}><strong>{order.billing_address.full_name}</strong></p>
                              <p style={{fontSize:15}}> {order.billing_address.address_line_1}, {order.billing_address.address_line_2 !== null && `,${order.billing_address.address_line_2}`}{order.billing_address.address_line_3 !== null && `,${order.billing_address.address_line_3}`} {order.billing_address.city}, {order.billing_address.state}, {order.billing_address.pin_code}, {order.billing_address.country}</p>
                              <p style={{fontSize:15}}>Phone number: {order.billing_address.phone_number}</p>
                             </Card.Body>
                          </Card>
                          </div>
                      </div>
                    </div>
                  </div>


                </ThemeModal>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Orders;