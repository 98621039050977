// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Apis } from "../../config";
// const CKEditorField = ({ field, form }) => {

//   function uploadAdapter(loader) {
//     var token = sessionStorage.getItem("userToken");
//     return {
//       upload: () => {
//         return new Promise((resolve, reject) => {
//           const body = new FormData();
//           loader.file.then((file) => {
//             body.append("ckFile", file);
//             fetch(`${Apis.UploadCkeditor}`, {
//               method: "POST",
//               body: body,
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             })
//               .then((res) => res.json())
//               .then((res) => {
//                 resolve({ default: res.image_url });
//               })
//               .catch((err) => {
//                 reject(err);
//               });
//           });
//         });
//       },
//     };
//   }

//   function uploadPlugin(editor) {
//     editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
//       return uploadAdapter(loader);
//     };
//   }

//     const { name, value } = field;
  
//     const handleEditorChange = (event, editor) => {
//       const data = editor.getData();
//       form.setFieldValue(name, data);
//     };
  
//     return (
//       <CKEditor
//         editor={ClassicEditor}
//         config={{
//           extraPlugins: [uploadPlugin],
//         }}
//         data={value}
//         onChange={handleEditorChange}
//       />
//     );
//   };

//   export default CKEditorField;
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const CKEditorField = ({ field, form }) => {
  const { name, value } = field;

  // Custom image upload handler
  const handleImageUpload = (blobInfo, success, failure) => {
    const token = sessionStorage.getItem("userToken");

    const formData = new FormData();
    formData.append("file", blobInfo.blob());

    fetch("YOUR_IMAGE_UPLOAD_API_URL", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        success(data.image_url); // Return the image URL to TinyMCE
      })
      .catch((error) => {
        failure("Image upload failed: " + error.message);
      });
  };

  const handleEditorChange = (content, editor) => {
    form.setFieldValue(name, content);
  };

  return (
    <Editor
      apiKey="rueh561lhycrgpckg0wk1bqtbzcjvx1g6vrap3phnnsarue9" // Use your API key here (or leave out for free version)
      value={value}
      init={{
        height: 500,
        menubar: true,
        menu: {
          happy: { title: 'Happy', items: 'code' }
        },
        plugins: [
          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
          'media', 'table', 'emoticons', 'help'
        ],
       
        toolbar:
        'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code', // Add 'code' here for the "View Source" button
      images_upload_handler: handleImageUpload,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      setup(editor) {
      },
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default CKEditorField;


