import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');


const createMedia = async (data) => {
  try {
    let result = await api.post(Apis.CreateMedia, data, {
      headers: {
        'x-access-token': token,
        "Content-Type": "multipart/form-data",
      }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createVideoMedia = async (data) => {
  try {
    let result = await api.post(Apis.CreateVideoMedia, data, {
      headers: {
        'x-access-token': token,
        "Content-Type": "multipart/form-data",
      }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getAllMedia = async (currentPage) => {
  try {
    let result = await api.get(`${Apis.GetAllMedia}?page=${currentPage}&pageSize=24`, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const deleteMedia = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteMedia+id, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const exportedObject = {
    getAllMedia,
    createMedia,
    deleteMedia,
    createVideoMedia
};
export default exportedObject;