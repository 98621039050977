
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FaEye } from "react-icons/fa";
import { Formik } from 'formik';
import { Alerts, GetProductCategory } from "../../../helpers";
import { IoMdRemoveCircle, IoIosSettings  } from "react-icons/io";
const CreateVariationOption = ({ variation, getVariations }) => {
    const [isShow, setShow] = useState(false);
    const handleSubmit = async (values, { setSubmitting }) => {
       const resp = await GetProductCategory.createVariationOption(values);
       if(resp && resp.status){
        //    setShow(false);
           setSubmitting(false);
           getVariations();
           Alerts.alertMessage(resp.message, 'top-right', 'success');
       }else{
           Alerts.alertMessage('Something went wrong !', 'top-right', 'danger');
       }
   };
   

   const removeVariationOption = async (removeId) =>{
     const resp = await GetProductCategory.deleteVariationOption(removeId);
     if(resp && resp.status){
           getVariations();
           Alerts.alertMessage(resp.message, 'top-right', 'success');
       }else{
           Alerts.alertMessage('Something went wrong !', 'top-right', 'danger');
       }
   }

    return (
        <>
            <button className='btn btn-success btn-sm' onClick={() => setShow(true)}><IoIosSettings size={14} /></button>
            <Modal
                show={isShow}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Variation Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                    enableReinitialize={true}
                        initialValues={{
                            variation_id: variation.id,
                            value: ''
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.variation_id) {
                                errors.variation_id = "Variation is required";
                            }
                            if (!values.value) {
                                errors.value = "Variation value is required";
                            }
                            return errors;
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    {/* <h4>{variation.variation_category.title}</h4> */}
                                    <h5>Variation Name - {variation.name}</h5>
                                </div>
                                <div className="form-group">
                                    <label>Variation Value</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="value"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.value}
                                    />
                                    {errors.value && touched.value && (
                                        <span className="help-error">{errors.value}</span>
                                    )}
                                </div>


                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Submitting..." : "Add Now"}
                                </button>
                            </form>
                        )}
                    </Formik>
                    {
                         variation.variation_options.length > 0 &&  <div className={"variation_option"}>
                         <h3>Options Value</h3>
                         <ul>
                             {
                                 variation.variation_options.map((item)=>{
                                     return <li key={item.id}>{item.value} <button className="removeOption" onClick={()=>removeVariationOption(item.id)}><IoMdRemoveCircle /></button></li>
                                 })
                             }
                             
                         </ul>
                     </div>
                    }
                    
                </Modal.Body>

            </Modal>
        </>
    );
}

export default CreateVariationOption;